import { useState } from 'react';
import styles from './Dashboard.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../../components/Logo';
import NavBarApoioCorban from '../../../components/NavBar/ApoioCorban';
import FilterComponent from '../../../components/Dashboard/Filtro';
import Card from '../../../components/Dashboard/Card';
import { FilterState, ManagerOption, PartnerOption } from '../../../interfaces/Dashboard/Filters';
import TermometroMaster from '../../../components/Dashboard/TermometroMaster';
import AnaliseConsultas from '../../../components/Dashboard/AnaliticoConsultas';
import AnaliseDigitacoes from '../../../components/Dashboard/DigitacaoConsultas';

export default function DashboardApoioCorban() {
  const producaoPeriodo = 50000;
  const consultasPeriodo = 75000;
  const producaoMediaMinima = 100000;
  const consultasMediaMinima = 75000;

  const [menuOpen, setMenuOpen] = useState<boolean>(
    localStorage.getItem('isMenuOpen') === 'true'
  );
  const [filters, setFilters] = useState<FilterState>({
    startDate: '',
    endDate: '',
    partner: null,
  });
  const [activeView, setActiveView] = useState<'dashboard' | 'consultas' | 'digitacoes'>('dashboard');

  const partners: PartnerOption[] = [
    { value: 'partner1', label: 'Parceiro 1' },
    { value: 'partner2', label: 'Parceiro 2' },
    { value: 'partner3', label: 'Parceiro 3' },
  ];
  const managers: ManagerOption[] = [
    { value: 'manager1', label: 'Gerente 1' },
    { value: 'manager2', label: 'Gerente 2' },
    { value: 'manager3', label: 'Gerente 3' },
  ];

  const handleFilterChange = (newFilters: FilterState) => {
    setFilters(newFilters);
  };

  const handleCardClick = (title: string) => {
    if (title === 'Total de Consultas') {
      setActiveView('consultas');
    } else if (title === 'Digitações no Acelere') {
      setActiveView('digitacoes');
    }
  };

  const handleBack = () => {
    setActiveView('dashboard');
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBarApoioCorban
          onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)}
        />
      </section>
      <Logo />
      <Container
        className={`${styles.contentSection} ${
          menuOpen ? styles.sideBarOpen : styles.sideBarClose
        } mt-5`}
      >
        <div className={`${menuOpen ? '' : styles.close}`}>
          <div className="d-flex justify-content-start mt-3 mb-5">
            <FilterComponent
              initialStartDate={filters.startDate}
              initialEndDate={filters.endDate}
              initialPartner={filters.partner}
              partnersList={partners}
              managerList={managers}
              onFilterChange={handleFilterChange}
            />
          </div>
          <div className={styles.transitionWrapper}>
            {activeView === 'consultas' ? (
              <AnaliseConsultas onBack={handleBack} />
            ) : activeView === 'digitacoes' ? (
              <AnaliseDigitacoes onBack={handleBack} />
            ) : (
              <div className={styles.fadeIn}>
                <Row className="mt-5">
                  <Col md={4} className="mt-5 mb-3">
                    <Card
                      title="Total de Consultas"
                      value={150}
                      typeExibition="int"
                      onClick={() => handleCardClick('Total de Consultas')}
                    />
                  </Col>
                  <Col md={4} className="mt-5 mb-3">
                    <Card
                      title="Digitações no Acelere"
                      value={22}
                      typeExibition="int"
                      onClick={() => handleCardClick('Digitações no Acelere')}
                    />
                  </Col> 
                  <Col md={4} className="mt-5 mb-3">
                    <Card
                      title="Aproveitamento Digitações"
                      value={33.23}
                      typeExibition="percent"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12}>
                    <TermometroMaster
                      producaoMediaMinima={producaoMediaMinima}
                      consultasMediaMinima={consultasMediaMinima}
                      producaoPeriodo={producaoPeriodo}
                      consultasPeriodo={consultasPeriodo}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}