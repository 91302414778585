import React, { useEffect, useState } from "react";
import { Modal, Alert, Spinner, Row } from "react-bootstrap";
import styles from './ConfirmRedirectTyping.module.scss';
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import axios, { AxiosError } from "axios";
import { renderBankName } from "../../../Utils";

interface ConfirmRedirectTypingModalProps {
  show: boolean;
  onHide: () => void;
  bankTyping: string | null;
  onSave: () => void;
  numberContract?: string | null; 
}

const ConfirmRedirectTypingModal: React.FC<ConfirmRedirectTypingModalProps> = ({
  show,
  onHide,
  onSave,
  bankTyping,
  numberContract
}) => {
  const [loading, setLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (show) {
      checkUserAccess();
    }
  }, [show]);

  const checkUserAccess = async () => {
    setLoading(true);
    setErrorMessage("");
    setHasAccess(null);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://webservices.gfttech.com.br/api/v1/acesso-usuario-banco?bank_name=${bankTyping}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
    } catch (error: unknown) {
      console.error("Erro ao verificar acesso:", error);
    
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        if (axiosError.response) {
          // A API retornou um status de erro, setamos hasAccess para false
          setHasAccess(false);
          
          // Verificação de tipo mais rigorosa
          const data = axiosError.response.data as Record<string, unknown>;
          if (typeof data.message === 'string') {
            setErrorMessage('Usuario Nao Identificado');
          } else {
            setErrorMessage("Erro ao verificar acesso.");
          }
        } else {
          // Erro de rede ou outro erro não relacionado ao status da API
          setHasAccess(false);
          setErrorMessage("Erro ao verificar acesso. Por favor, tente novamente.");
        }
      } else {
        // Erro desconhecido
        setHasAccess(false);
        setErrorMessage("Erro desconhecido ao verificar acesso.");
      }
    } finally {
      setLoading(false);
    }
  }

  const handleSave = () => {
    if (hasAccess) {
      onSave(); // Chama a função de salvar passada como prop se o usuário tiver acesso
    }
  };

  return (
    <Modal show={show} className={styles.modal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Prosseguir com a Digitação?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
           <Row>
            <div className="d-flex justify-content-center">
              <Spinner animation="border" />
            </div>
          </Row>
        ) : (
          <>
            {hasAccess === false && (
              <Alert variant="danger">
                {errorMessage ? errorMessage : `Você não possui usuário cadastrado no ${renderBankName(bankTyping)}.`}
              </Alert>
            )}
            {hasAccess === true && (
              <Alert variant="success">
                Você possui usuário cadastrado no {renderBankName(bankTyping)}.
              </Alert>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary 
          content={loading ? (
            <>
              <Spinner animation="border" size="sm" />
            </>
          ) : "Prosseguir"}
          onClick={handleSave}
          disabled={loading || hasAccess === false || hasAccess === null} // Desabilita o botão se estiver carregando, sem acesso ou se ainda não foi verificado
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmRedirectTypingModal;