import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import styles from './TermometroMaster.module.scss';
import { formatCurrency } from '../../../Utils';

interface TermometroMasterProps {
  producaoMediaMinima: number;
  consultasMediaMinima: number;
  producaoPeriodo: number;
  consultasPeriodo: number;
}

const TermometroMaster: React.FC<TermometroMasterProps> = ({
  producaoMediaMinima,
  consultasMediaMinima,
  producaoPeriodo,
  consultasPeriodo,
}) => {
  const [selectedOption, setSelectedOption] = useState('Geral');

  const producaoPorConsultaEsperada = producaoMediaMinima / consultasMediaMinima;
  const producaoPorConsultaReal = consultasPeriodo > 0 ? producaoPeriodo / consultasPeriodo : 0;
  const indiceEficiencia = (producaoPorConsultaReal / producaoPorConsultaEsperada) * 100;

  const getStatus = () => {
    if (indiceEficiencia >= 100) return { variant: 'success', label: 'Excelente' };
    if (indiceEficiencia >= 70) return { variant: 'warning', label: 'Razoável' };
    return { variant: 'danger', label: 'Ruim' };
  };

  const { variant, label } = getStatus();

  const formatNumber = (value: number) =>
    value.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

  const options = [
    { value: 'Geral', label: 'Geral' },
    { value: 'Master', label: 'Master' },
  ];

  return (
    <div className={styles.termometroContainer}>
      <div className={styles.header}>
        <h5 className={styles.title}>Termômetro de Eficiência</h5>
        <div className={styles.selectContainer}>
          <Select
            options={options}
            defaultValue={options[0]}
            onChange={(option) => setSelectedOption(option?.value || 'Geral')}
            className={styles.reactSelect}
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <div className={styles.metrics}>
        <p>
          <strong>Produção Real:</strong> {formatCurrency(producaoPeriodo)} |{' '}
          <strong>Consultas:</strong> {formatNumber(consultasPeriodo)}
        </p>
        <p>
          <strong>Mínimo Esperado:</strong> {formatCurrency(producaoMediaMinima)} /{' '}
          {formatNumber(consultasMediaMinima)} consultas
        </p>
      </div>
      <ProgressBar
        now={Math.min(indiceEficiencia, 150)}
        variant={variant}
        className={styles.progress}
        label={`${indiceEficiencia.toFixed(1)}% - ${label}`}
      />
      <div className={styles.legend}>
        <span className={styles.success}>≥ 100%: Excelente</span>
        <span className={styles.warning}>70-99%: Razoável</span>
        <span className={styles.danger}>{'>'} 70%: Ruim</span>
      </div>
    </div>
  );
};

export default TermometroMaster;