import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faMoneyBill1Wave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import ButtonPrimary from '../../Buttons/ButtonPrimary';
import styles from './AnaliseDigitacoes.module.scss';

interface AnaliseDigitacoesProps {
  onBack: () => void;
}

interface ChartDataPoint {
  date: string;
  Paga: number;
  'Em Análise': number;
  Cancelada: number;
}

const AnaliseDigitacoes: React.FC<AnaliseDigitacoesProps> = ({ onBack }) => {
  const [displayType, setDisplayType] = useState<'quantidade' | 'volume'>('quantidade');
  const [selectedStatus, setSelectedStatus] = useState<'Paga' | 'Em Análise' | 'Cancelada' | null>(null);
  const [timeFrame, setTimeFrame] = useState<'semanal' | 'mensal'>('semanal');
  const [numeroPropostaFilter, setNumeroPropostaFilter] = useState<string>(''); // Filtro de número da proposta
  const [clienteFilter, setClienteFilter] = useState<string>(''); // Filtro de cliente

  // Dados fictícios
  const cardData = {
    pagas: { quantidade: 25, volume: 50000 },
    emAnalise: { quantidade: 15, volume: 30000 },
    canceladas: { quantidade: 10, volume: 20000 },
  };

  const chartDataSemanal: ChartDataPoint[] = [
    { date: '2025-01-01', Paga: 2, 'Em Análise': 2, Cancelada: 1 },
    { date: '2025-01-02', Paga: 4, 'Em Análise': 3, Cancelada: 3 },
    { date: '2025-01-03', Paga: 6, 'Em Análise': 5, Cancelada: 4 },
    { date: '2025-01-04', Paga: 5, 'Em Análise': 4, Cancelada: 3 },
    { date: '2025-01-05', Paga: 8, 'Em Análise': 1, Cancelada: 2 },
  ];

  const chartDataMensal: ChartDataPoint[] = [
    { date: '2025-01', Paga: 25, 'Em Análise': 15, Cancelada: 10 },
    { date: '2025-02', Paga: 30, 'Em Análise': 20, Cancelada: 15 },
    { date: '2025-03', Paga: 35, 'Em Análise': 25, Cancelada: 20 },
  ];

  const tableData = [
    { numeroProposta: 'P001', cliente: 'João Silva', banco: 'Banco A', status: 'Paga' },
    { numeroProposta: 'P002', cliente: 'Maria Souza', banco: 'Banco B', status: 'Em Análise' },
    { numeroProposta: 'P003', cliente: 'Pedro Lima', banco: 'Banco C', status: 'Cancelada' },
  ];

  const selectOptions = [
    { value: 'quantidade', label: 'Quantidade' },
    { value: 'volume', label: 'Volume' },
  ];

  const timeFrameOptions = [
    { value: 'semanal', label: 'Semanal' },
    { value: 'mensal', label: 'Mensal' },
  ];

  const formatValue = (quantidade: number, volume: number) =>
    displayType === 'quantidade' ? `${quantidade} propostas` : `R$ ${volume.toLocaleString('pt-BR')}`;

  const handleCardClick = (status: 'Paga' | 'Em Análise' | 'Cancelada') => {
    setSelectedStatus(status === selectedStatus ? null : status);
  };

  // Filtra a tabela com base no status e nos inputs
  const filteredTableData = tableData.filter((item) => {
    const matchesStatus = selectedStatus ? item.status === selectedStatus : true;
    const matchesNumeroProposta = numeroPropostaFilter
      ? item.numeroProposta.toLowerCase().includes(numeroPropostaFilter.toLowerCase())
      : true;
    const matchesCliente = clienteFilter
      ? item.cliente.toLowerCase().includes(clienteFilter.toLowerCase())
      : true;
    return matchesStatus && matchesNumeroProposta && matchesCliente;
  });

  const filteredChartData = (timeFrame === 'semanal' ? chartDataSemanal : chartDataMensal).map((item) => ({
    date: item.date,
    propostas: selectedStatus ? item[selectedStatus] : item.Paga + item['Em Análise'] + item.Cancelada,
  }));

  const handleExport = () => {
    alert('Exportação iniciada!');
  };

  return (
    <div className={`${styles.analiseContainer} ${styles.fadeIn}`}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack}>
          ←
        </button>
        <h5 className={styles.title}>Analítico Digitações</h5>
        <div className={styles.selectContainer}>
          <Select
            options={selectOptions}
            defaultValue={selectOptions[0]}
            onChange={(option) => setDisplayType((option?.value as 'quantidade' | 'volume') || 'quantidade')}
            className={styles.reactSelect}
            classNamePrefix="react-select"
          />
        </div>
      </div>

      <div className={styles.cardsContainer}>
        <div
          className={`${styles.card} ${selectedStatus === 'Paga' ? styles.selected : ''}`}
          onClick={() => handleCardClick('Paga')}
        >
          <div className={styles.cardIcon}><FontAwesomeIcon icon={faMoneyBill1Wave} /></div>
          <div className={styles.cardContent}>
            <span className={styles.cardTitle}>Propostas Pagas</span>
            <span className={styles.cardValue}>{formatValue(cardData.pagas.quantidade, cardData.pagas.volume)}</span>
          </div>
        </div>
        <div
          className={`${styles.card} ${selectedStatus === 'Em Análise' ? styles.selected : ''}`}
          onClick={() => handleCardClick('Em Análise')}
        >
          <div className={styles.cardIcon}><FontAwesomeIcon icon={faFileAlt} /></div>
          <div className={styles.cardContent}>
            <span className={styles.cardTitle}>Propostas Em Análise</span>
            <span className={styles.cardValue}>{formatValue(cardData.emAnalise.quantidade, cardData.emAnalise.volume)}</span>
          </div>
        </div>
        <div
          className={`${styles.card} ${selectedStatus === 'Cancelada' ? styles.selected : ''}`}
          onClick={() => handleCardClick('Cancelada')}
        >
          <div className={styles.cardIcon}><FontAwesomeIcon icon={faTimes} /></div>
          <div className={styles.cardContent}>
            <span className={styles.cardTitle}>Propostas Canceladas</span>
            <span className={styles.cardValue}>{formatValue(cardData.canceladas.quantidade, cardData.canceladas.volume)}</span>
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartHeader}>
          <Select
            options={timeFrameOptions}
            defaultValue={timeFrameOptions[0]}
            onChange={(option) => setTimeFrame((option?.value as 'semanal' | 'mensal') || 'semanal')}
            className={styles.timeFrameSelect}
            classNamePrefix="react-select"
          />
        </div>
        <div className={selectedStatus || timeFrame ? styles.fadeTransition : ''}>
          <LineChart width={1200} height={250} data={filteredChartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="propostas" stroke="#153B7F" />
          </LineChart>
        </div>
      </div>

      <Row className={styles.filterRow}>
        <Col md={4}>
          <div className={styles.floatingLabel}>
            <input
              type="text"
              value={numeroPropostaFilter}
              onChange={(e) => setNumeroPropostaFilter(e.target.value)}
              placeholder=" "
              className={styles.floatingInput}
            />
            <label className={styles.floatingLabelText}>Número da Proposta</label>
          </div>
        </Col>
        <Col md={4}>
          <div className={styles.floatingLabel}>
            <input
              type="text"
              value={clienteFilter}
              onChange={(e) => setClienteFilter(e.target.value)}
              placeholder=" "
              className={styles.floatingInput}
            />
            <label className={styles.floatingLabelText}>Cpf Cliente</label>
          </div>
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <ButtonPrimary content="Exportar" onClick={handleExport} />
        </Col>
      </Row>

      <div className={`${styles.tableContainer} ${selectedStatus || numeroPropostaFilter || clienteFilter ? styles.fadeTransition : ''}`}>
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Número Proposta</th>
              <th>Cliente</th>
              <th>Banco</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredTableData.map((item, index) => (
              <tr key={index}>
                <td>{item.numeroProposta}</td>
                <td>{item.cliente}</td>
                <td>{item.banco}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AnaliseDigitacoes;