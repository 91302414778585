import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import styles from "../VirtualWallet.module.scss";
import NavBar from "../.././../components/NavBar";
import { RecordData } from "../../../interfaces/VirtualWallet";
import PageTitle from "../../../components/PageTitle";
import PageSubTitle from "../../../components/PageSubTitle";

const ConfirmDataClient: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const subtitleText = "Confirme os dados do cliente para efetivar a digitação.";

  return (
    <div className={`${styles.walletContainer} mt-5`}>
      <div className={`${styles.titlesSection}`}>
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Carteira Virtual</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-5`}>
          <PageSubTitle size='h5'>{subtitleText}</PageSubTitle>
        </div>
      </div>

      <NavBar />

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
        
        </>
      )}
    </div>
  );
};

export default ConfirmDataClient;