import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';

interface Bank {
  id?: number;
  name: string;
  code: string | null;
}

interface CreateEditBankModalProps {
  show: boolean;
  onHide: () => void;
  onSave: (bank: Bank) => void;
}

const CreateEditBankModal: React.FC<CreateEditBankModalProps> = ({
  show,
  onHide,
  onSave,
}) => {
  const [banks, setBanks] = useState<Bank[]>([]);
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [portTax, setPortTax] = useState<string>('');
  const [refinTax, setRefinTax] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get('https://brasilapi.com.br/api/banks/v1');
        setBanks(response.data);
      } catch (error) {
        console.error('Erro ao buscar bancos:', error);
      }
    };

    fetchBanks();
  }, []);

  const handleSave = async () => {
    if (!selectedBank || !portTax || !refinTax) {
      alert('Por favor, preencha todos os campos.');
      return;
    }

    const bankToSave: Bank = {
      name: selectedBank.name,  // Usando 'name' em vez de 'bankName'
      code: selectedBank.code ? selectedBank.code : null,  // Usando 'code' em vez de 'bankCode'
    };

    try {
      setLoading(true);
      await axios.post('https://bi.gfttech.com.br/port/banks', {
        bankName: bankToSave.name,
        bankCode: bankToSave.code ? parseInt(bankToSave.code) : 0,
        portTax: parseFloat(portTax),
        refinTax: parseFloat(refinTax),
      });
      alert('Banco criado com sucesso!');
      onSave(bankToSave);
      onHide();
    } catch (error: any) {
      if(error.response.data.statusCode === 409){
        alert("Banco já existente!")
      }else{
        alert("Erro ao criar banco!")      
      }
    } finally {
      setLoading(false);
    }
  };

  const bankOptions = banks.map((bank) => ({
    value: bank.code,
    label: `${bank.code || 'N/A'} - ${bank.name}`,
    ...bank,
  }));

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Criar Banco</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Banco</Form.Label>
            <Select
              options={bankOptions}
              onChange={(selectedOption) =>
                setSelectedBank(selectedOption as Bank)
              }
              placeholder="Selecione um banco"
              isClearable
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Taxa de Portabilidade</Form.Label>
            <Form.Control
              type="number"
              value={portTax}
              onChange={(e) => setPortTax(e.target.value)}
              placeholder="Digite a taxa de portabilidade"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Taxa de Refinanciamento</Form.Label>
            <Form.Control
              type="number"
              value={refinTax}
              onChange={(e) => setRefinTax(e.target.value)}
              placeholder="Digite a taxa de refinanciamento"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={loading}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={loading}>
          {loading ? 'Salvando...' : 'Salvar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditBankModal;
