import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Table, Badge, Alert, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './ResultSimulation.module.scss';
import { motion } from 'framer-motion';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import NavBar from '../../../../components/NavBar';
import inssLogo from '../../../../assets/images/inss-logo.png';
import bmgLogo from '../../../../assets/images/banco bmg.png';
import c6Logo from '../../../../assets/images/banco c6.png';
import masterLogo from '../../../../assets/images/banco master.png';
import queromaisLogo from '../../../../assets/images/queromaislogo.png';
import daycovalLogo from '../../../../assets/images/daycoval-logo.png'
import factaLogo from '../../../../assets/images/logo-facta.png'
import { SimulationResult } from '../../../../interfaces/ReturnUnitaryInss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileLines, faFilePen, faRotateRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatCPF, formatCurrency } from '../../../../Utils';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ButtonGrayUltimate from '../../../../components/Buttons/ButtonGrayUltimate';
import ConfirmRedirectTypingModal from '../../../../components/Modals/ReturnSimulation';
import SimpleDigitationModal from '../../../../components/Modals/SimpleDigitation';
import ButtonYellow from '../../../../components/Buttons/ButtonYellow';
import ResumeClientModal from '../../../../components/Modals/VirtualWallet/ResumeClient';

interface Condition {
  produto: string | null;
  parcelas: number | null;
  valorParcela: number | null;
  valorLiquido: number | null;
  taxaCliente: number | null;
  valorSaque?: number | null;
  valorCompra?: number | null;
  valorLimite?: number | null;
  valorTroco?: number | null;
  status: string | null;
  id: string | null;
}


const ResultSimulationInss: React.FC = () => {
    const [result, setResult] = useState<SimulationResult | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
    const [selectedBank, setSelectedBank] = useState<string | null>(null);
    const [selectedIdSimulation, setSelectedIdSimulation] = useState<string | null>(null);
    const [showDigitationModal, setShowDigitationModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showModalExtrato, setShowModalExtrato] = useState(false);
    const [selectedBankForTyping, setSelectedBankForTyping] = useState<string | null>(null);
    const subtitleTextClient = "Retorno da sua Consulta.";

    useEffect(() => {
        const storedResult = localStorage.getItem('rs');
        if (storedResult) {
            const parsedResult = JSON.parse(storedResult);
            setResult(parsedResult);

            let hasOpportunities = false;
            if (parsedResult.condicoes) {
                for (const bank in parsedResult.condicoes) {
                    if (parsedResult.condicoes[bank].some((cond: any) => cond.status === 'success')) {
                        hasOpportunities = true;
                        break;
                    }
                }
            }

            setShowAlert(!hasOpportunities);
        }
    }, []);

    const getLogoPath = (bank: string) => {
        switch (bank) {
            case 'bmg': return bmgLogo;
            case 'master': return masterLogo;
            case 'c6': return c6Logo;
            case 'queromais': return queromaisLogo;
            case 'daycoval': return daycovalLogo;
            case 'facta': return factaLogo;
            default: return '';
        }
    };

    const handleCardClick = (bank: string) => {
        setSelectedBank(bank === selectedBank ? null : bank);
    };

    const tableVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    };

    const handleNewSimulation = () => {
        window.location.href = "/ConsultaUnitaria/Inss/DadosCliente";
    };

    const handleModalExtrato = () => {
      setShowModalExtrato(true)
    };

    const handleOpenModal = (bank: string | null, idSimulation: string | null) => {
        setSelectedBankForTyping(bank);
        setSelectedIdSimulation(idSimulation)
        setShowModalConfirm(true);
    };

    const handleConfirmSave = () => {
        setShowModalConfirm(false);
        setShowDigitationModal(true);
    };

    const renderOpportunitiesTable = (bank: string, conditions: Condition[], isCartao: boolean) => {
        const filteredConditions = conditions.filter(cond => cond.status === 'success' && (
            isCartao ? (cond.produto === 'Cartão RCC' || cond.produto === 'Cartão RMC') : (cond.produto !== 'Cartão RCC' && cond.produto !== 'Cartão RMC')
        ));

        if (filteredConditions.length === 0) {
            return null;
        }

        return (
            <motion.div
                className={`mt-4 ${styles.expandedTable}`}
                variants={tableVariants}
                initial="hidden"
                animate="visible"
            >
                <h4 className='mb-3'>{isCartao ? 'Oportunidades de Cartão' : 'Oportunidades de Empréstimo'}</h4>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Produto</th>
                            {isCartao ? (
                                <>
                                    <th>Valor Saque</th>
                                    <th>Valor Compra</th>
                                    <th>Valor Limite</th>
                                </>
                            ) : (
                                <>
                                    <th>Parcelas</th>
                                    <th>Valor da Parcela</th>
                                    <th>Valor Líquido</th>
                                    <th>Taxa Cliente</th>
                                </>
                            )}
                            {(bank === 'c6' || bank === 'facta') && (
                                <th>Digitar</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredConditions.map((condition, index) => (
                            <tr key={index}>
                                <td>{condition.produto}</td>
                                {isCartao ? (
                                    <>
                                        <td>{formatCurrency(condition.valorSaque)}</td>
                                        <td>{formatCurrency(condition.valorCompra)}</td>
                                        <td>{formatCurrency(condition.valorLimite)}</td>
                                    </>
                                ) : (
                                    <>
                                        <td>{condition.parcelas}</td>
                                        <td>{formatCurrency(condition.valorParcela)}</td>
                                        <td>{ condition.produto === "REFINANCIAMENTO" ? formatCurrency(condition.valorTroco) : formatCurrency(condition.valorLiquido)}</td>
                                        <td>{condition.taxaCliente} %</td>
                                    </>
                                )}
                                {(bank === 'c6' || bank === 'facta') && (
                                    <td>
                                        <ButtonGrayUltimate
                                            content={<FontAwesomeIcon icon={faFilePen} />}
                                            onClick={() => handleOpenModal(bank, condition.id)}
                                        />
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </motion.div>
        );
    };

    return (
        <>
            <section className={styles.navbarSection}>
                <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
            </section>

            <motion.div
                initial={{ x: 1000, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 1000, opacity: 0 }}
                transition={{ duration: 1.2 }}
            >
                <div className={`${styles.titlesSection} mt-5`}>
                    <div className={`${styles.titleSection}`}>
                        <PageTitle size="h2">Consulta Unitária INSS</PageTitle>
                    </div>
                    <div className={`${styles.subTitleSection}`}>
                        <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
                    </div>
                </div>
                <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
                    {result && (
                        <>
                            <Row className='mb-3'>
                                <div className='d-flex justify-content-end gap-4'>
                                    <ButtonYellow
                                      content={
                                        <>
                                            <FontAwesomeIcon icon={faFileLines} className='me-2' /> <span>Extrato</span>
                                        </>
                                      }
                                      onClick={handleModalExtrato}
                                    />
                                    <ButtonPrimary
                                      content={
                                        <>
                                            <FontAwesomeIcon icon={faRotateRight} className='me-2' /> <span>Simular Novamente</span>
                                        </>
                                      }
                                      onClick={handleNewSimulation}
                                    />
                                </div>
                            </Row>
                            <Card className={`${styles.resultCard} mb-5`}>
                                <Card.Header className={`${styles.headerCard} ${styles.neutralHeader}`}>
                                    <Row className="align-items-center">
                                        <Col md={2} className="d-flex align-items-center justify-content-center">
                                            <img src={inssLogo} alt="INSS Logo" className={styles.inssLogo} />
                                        </Col>
                                        <Col md={9}>
                                            <Row>
                                                <Col md={6}>
                                                    <ListGroup as="ol">
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">Nome:</div>
                                                            </div>
                                                            <Badge className={styles.badgeStyle} pill>
                                                                {result.nomeCliente}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">CPF:</div>
                                                            </div>
                                                            <Badge className={styles.badgeStyle} pill>
                                                                {formatCPF(result.cpf)}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">Matrícula:</div>
                                                            </div>
                                                            <Badge className={styles.badgeStyle} pill>
                                                                {result.numeroBeneficio}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">Status Benefício:</div>
                                                            </div>
                                                            <Badge className={styles.badgeStyle} pill>
                                                              {result.margem.situacaoBeneficio === 0 ? 'ATIVO' : 'NÃO ATIVO'}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <ListGroup as="ol">
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">Margem Empréstimo:</div>
                                                            </div>
                                                            <Badge className={styles.badgeStyleSecondary} pill>
                                                                {formatCurrency(result.margem.margemDisponivel)}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">Margem Cartão RMC:</div>
                                                            </div>
                                                            <Badge className={styles.badgeStyleSecondary} pill>
                                                                {formatCurrency(result.margem.margemDisponivelCartao)}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">Margem Cartao RCC:</div>
                                                            </div>
                                                            <Badge className={styles.badgeStyleSecondary} pill>
                                                                {formatCurrency(result.margem.margemDisponivelRCC)}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">Elegível Empréstimo:</div>
                                                            </div>
                                                            <div className={result.margem.bloqueadoParaEmprestimo ? styles.alertDanger : styles.alertSuccess}>
                                                              <FontAwesomeIcon
                                                                icon={result.margem.bloqueadoParaEmprestimo ? faTimes : faCheck}
                                                                className={result.margem.bloqueadoParaEmprestimo ? styles.iconError : styles.iconSuccess}
                                                              />
                                                              <span className="ms-2">
                                                                {result.margem.bloqueadoParaEmprestimo ? 'Indisponível' : 'Disponível'}
                                                              </span>
                                                            </div>
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </Card.Header>
                            </Card>
                            {showAlert && (
                                <Alert variant="secondary" className="d-flex justify-content-between align-items-center">
                                    <span>Nenhuma oportunidade localizada para o benefício.</span>
                                    <Button variant="outline-dark" onClick={handleNewSimulation}>
                                        Outra Simulação
                                    </Button>
                                </Alert>
                            )}
                            <Row>
                                {result.condicoes && Object.entries(result.condicoes).map(([bank, conditions], index) => {
                                    const successConditions = conditions.filter(cond => cond.status === 'success');
                                    const logoPath = getLogoPath(bank);
                                    if (successConditions.length > 0 && logoPath) {
                                        return (
                                            <Col xs={6} md={4} lg={2} key={index} className="mb-3">
                                                <Card
                                                    onClick={() => handleCardClick(bank)}
                                                    className={`${styles.bankCard} ${selectedBank === bank ? styles.selected : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className={styles.logoContainerSmall}>
                                                        <Card.Img variant="top" src={logoPath} alt={`${bank} Logo`} className={styles.bankLogoSmall} />
                                                    </div>
                                                    <Card.Body className="text-center">
                                                        Oportunidades: <Badge bg="secondary">{successConditions.length}</Badge>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    }
                                    return null;
                                })}
                            </Row>

                            {selectedBank && result.condicoes && result.condicoes[selectedBank] && (
                                <>
                                    {renderOpportunitiesTable(selectedBank, result.condicoes[selectedBank], false)}
                                    {renderOpportunitiesTable(selectedBank, result.condicoes[selectedBank], true)}
                                </>
                            )}
                        </>
                    )}
                </Container>
            </motion.div>
            <ConfirmRedirectTypingModal
              show={showModalConfirm}
              bankTyping={selectedBankForTyping}
              onHide={() => setShowModalConfirm(false)}
              onSave={handleConfirmSave}
            />
            <SimpleDigitationModal
              show={showDigitationModal}
              onHide={() => setShowDigitationModal(false)}
              idSimulation={selectedIdSimulation}
              bankTyping={selectedBankForTyping}
              onSave={() => {
                setShowDigitationModal(false);
              }}
              dadosCliente={{
                cpf: result?.cpf || '',
                numeroBeneficio: result?.numeroBeneficio || '',
                dataNascimento: result?.dataNascimento || ''
              }}
            />
            <ResumeClientModal
              onHide={() => setShowModalExtrato(false)}
              show={showModalExtrato}
              selectedLot={[{ cpf: result?.cpf || '' }]} 
            />
        </>
    );
};

export default ResultSimulationInss;