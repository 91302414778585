import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';

interface FilterBatchProps {
  onFilter: (search: string, startDate: string, endDate: string) => void;
}

export default function FilterBatch({ onFilter }: FilterBatchProps) {
  const [search, setSearch] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const handleSearch = () => {
    onFilter(search, startDate, endDate); // Chama a função onFilter ao clicar
  };

  return (
    <Form>
      <Row className="align-items-center">
        <Col xs={12} md={4}>
          <Form.Group controlId="formSearch">
            <Form.Control
              type="text"
              placeholder="Pesquisar Protocolo do lote..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form.Group>
        </Col>

        <Col xs={6} md={3}>
          <Form.Group controlId="formStartDate">
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Group>
        </Col>

        <Col xs={6} md={3}>
          <Form.Group controlId="formEndDate">
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={2} className="d-flex align-items-end">
          <ButtonPrimary 
            content={<><FontAwesomeIcon icon={faSearch} /></>} 
            onClick={handleSearch}
          />
        </Col>
      </Row>
    </Form>
  );
}