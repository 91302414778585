import React, { useState, useEffect } from 'react';
import { Form, FloatingLabel, Col } from 'react-bootstrap';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FilterState, ManagerOption, PartnerOption } from '../../../interfaces/Dashboard/Filters';

interface FilterComponentProps {
  initialStartDate?: string;
  initialEndDate?: string;
  initialPartner?: PartnerOption | null;
  initialManager?: ManagerOption | null;
  partnersList?: PartnerOption[];
  managerList?: ManagerOption[];

  onFilterChange: (filters: FilterState) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  initialStartDate = '',
  initialEndDate = '',
  initialPartner = null,
  initialManager = null,
  partnersList = [],
  managerList = [],
  onFilterChange,
}) => {
  const [startDate, setStartDate] = useState<string>(initialStartDate);
  const [endDate, setEndDate] = useState<string>(initialEndDate);
  const [selectedPartner, setSelectedPartner] = useState<PartnerOption | null>(initialPartner);
  const [selectedManager, setSelectedManager] = useState<ManagerOption | null>(initialManager);

  const notifyParent = () => onFilterChange({ startDate, endDate, partner: selectedPartner });
  useEffect(() => notifyParent(), [startDate, endDate, selectedPartner]);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value);
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value);
  const handlePartnerChange = (selectedOption: PartnerOption | null) => setSelectedPartner(selectedOption);
  const handleManagerChange = (selectedOption: ManagerOption | null) => setSelectedManager(selectedOption);

  return (
    <>
      <Col className='me-4' md={2}>
        <FloatingLabel controlId="startDate" label="Data Início">
            <Form.Control
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            placeholder="Data Início"
            />
        </FloatingLabel>
      </Col>
      <Col className='me-4' md={2}>
        <FloatingLabel controlId="endDate" label="Data Fim">
          <Form.Control
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            placeholder="Data Fim"
          />
        </FloatingLabel>
      </Col>
      <Col className='me-4' md={4}>
        <Select
          options={managerList}
          value={selectedManager}
          onChange={handleManagerChange}
          placeholder="Selecione um gerente"
          isClearable
          styles={{
          control: (base) => ({
              ...base,
              height: '58px',
              minHeight: '58px',
              borderRadius: '0.375rem',
              display: 'flex',
              alignItems: 'center',
          }),
          valueContainer: (base) => ({
              ...base,
              height: '100%',
              padding: '0 0.75rem',
          }),
          singleValue: (base) => ({
              ...base,
              margin: 0,
          }),
          indicatorsContainer: (base) => ({
              ...base,
              height: '100%',
          }),
          }}
        />
      </Col>
      <Col className='me-4' md={4}>
        <Select
          options={partnersList}
          value={selectedPartner}
          onChange={handlePartnerChange}
          placeholder="Selecione um parceiro"
          isClearable
          styles={{
          control: (base) => ({
              ...base,
              height: '58px',
              minHeight: '58px',
              borderRadius: '0.375rem',
              display: 'flex',
              alignItems: 'center',
          }),
          valueContainer: (base) => ({
              ...base,
              height: '100%',
              padding: '0 0.75rem',
          }),
          singleValue: (base) => ({
              ...base,
              margin: 0,
          }),
          indicatorsContainer: (base) => ({
              ...base,
              height: '100%',
          }),
          }}
        />
      </Col>
    </>
  );
};

export default FilterComponent;