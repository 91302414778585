import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import axios, { AxiosError } from "axios";
import styles from './SimpleDigitation.module.scss';
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import Simulacao from "../../../interfaces/SimpleDigitation/Simulation";
import { formatarCPF, formatarTelefone, formatBankCode, formatCurrency, validarCPF } from "../../../Utils";
import ToastWarning from '../../../components/ToastWarning';

interface SimpleDigitationModalProps {
  show: boolean;
  onHide: () => void;
  idSimulation: any | null;
  bankTyping: string | null;
  dadosCliente: {
    cpf: string;
    numeroBeneficio: string;
    dataNascimento: string;
  };
  onSave: () => void;
  numberContract?: string | null;
}

const SimpleDigitationModal: React.FC<SimpleDigitationModalProps> = ({
  show,
  onHide,
  onSave,
  idSimulation,
  bankTyping,
  dadosCliente,
  numberContract
}) => {
  const [simulacoes, setSimulacoes] = useState<Simulacao[]>([]);
  const [selectedSimulacao, setSelectedSimulacao] = useState<Simulacao | null>(null);
  const [toastMessage, setToastMessage] = useState('');
  const [dadosBancarios, setDadosBancarios] = useState({
    agencia: "",
    conta: "",
    banco: "",
    tipoConta: "",
    celular: ""
  });
  const [loading, setLoading] = useState(false);

  const [bancos, setBancos] = useState<any[]>([]);

  const tipoContasC6 = [
    { value: "CONTA_CORRENTE", label: "CONTA_CORRENTE" },
    { value: "CONTA_POUPANCA", label: "CONTA_POUPANCA" },
    { value: "CONTA_SALARIO", label: "CONTA_SALARIO" },
    { value: "CONTA_CORRENTE_CONJUNTA", label: "CONTA_CORRENTE_CONJUNTA" },
    { value: "CONTA_POUPANCA_CONJUNTA", label: "CONTA_POUPANCA_CONJUNTA" }
  ];

  const tipoContasFacta = [
    { value: "CC", label: "CONTA_CORRENTE" },
    { value: "CP", label: "CONTA_POUPANCA" },
    { value: "CM", label: "CARTÃO_MAGNÉTICO" },
  ]

  const [clienteIletrado, setClienteIletrado] = useState(false);
  const [dadosIletrado, setDadosIletrado] = useState({
    nomeARogo: "",
    cpfARogo: "",
    nomeARogoTestemunha: "",
    cpfARogoTestemunha: ""
  });

  useEffect(() => {
    if (show) {
      setSelectedSimulacao(null);
      setToastMessage('');
      setDadosBancarios({
        agencia: "",
        conta: "",
        banco: "",
        tipoConta: "",
        celular: ""
      });
      setClienteIletrado(false);
      setDadosIletrado({
        nomeARogo: "",
        cpfARogo: "",
        nomeARogoTestemunha: "",
        cpfARogoTestemunha: ""
      });
    }
  }, [show]);

  useEffect(() => {
    if (bankTyping === "facta" && idSimulation) {
      const fetchSimulacoes = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(
            `https://acelereai.gfttech.com.br/api/v2/engine/simulate/credit-conditions/${idSimulation}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );
          // Adaptando o retorno para o formato esperado pela interface Simulacao
          const adaptedSimulacoes = response.data.map((item: any) => ({
            idSimulacao: item._id,
            descricaoTabela: item.tabela + " || PRAZO " + item.prazo + "x",
            valorLiquido: item.valor_liquido,
            valorBruto: item.contrato, // Supondo que 'contrato' seja o valor bruto
            valorCliente: item.valor_liquido, // Supondo que o valor líquido seja igual ao valor do cliente
            valorParcela: item.parcela,
            parcelas: item.prazo,
            taxaMensalCliente: item.taxa
          }));
          setSimulacoes(adaptedSimulacoes);
        } catch (error) {
          console.error("Erro ao buscar simulações:", error);
        }
      }
      fetchSimulacoes()
    } else if (bankTyping === "c6" && idSimulation) {
      const fetchSimulacoes = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(
            `https://acelereai.gfttech.com.br/api/v2/engine/simulate/credit-conditions/${idSimulation}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );

          const adaptedSimulacoes = response.data.map((item: any) => ({
            idSimulacao: item._id,
            descricaoTabela: item.descricaoTabela,
            valorLiquido: item.valorLiquido,
            valorBruto: item.valorBruto,
            valorCliente: item.valorCliente,
            valorParcela: item.valorParcela,
            parcelas: item.parcelas,
            taxaMensalCliente: item.taxaMensalCliente
          }));
          setSimulacoes(adaptedSimulacoes);
        } catch (error) {
          console.error("Erro ao buscar simulações:", error);
        }
      };
      fetchSimulacoes();
    }
  }, [bankTyping, idSimulation]);

  const handleSelectChange = (selectedOption: any) => {
    const simulacaoSelecionada = simulacoes.find(sim => sim.idSimulacao === selectedOption.value);
    setSelectedSimulacao(simulacaoSelecionada || null);
  };

  const handleBankDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "celular") {
      const apenasNumeros = value.replace(/\D/g, '').slice(0, 11);
      setDadosBancarios(prevState => ({
        ...prevState,
        [name]: formatarTelefone(apenasNumeros, bankTyping)
      }));
    } else {
      setDadosBancarios(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleDadosIletradoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "cpfARogo" || name === "cpfARogoTestemunha") {
      const apenasNumeros = value.replace(/\D/g, '').slice(0, 11);
      setDadosIletrado(prevState => ({
        ...prevState,
        [name]: formatarCPF(apenasNumeros)
      }));
    } else {
      setDadosIletrado(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  const handleSave = async () => {
    if (!selectedSimulacao) {
      setToastMessage("Por favor, selecione uma simulação antes de continuar.");
      return;
    }

    if (clienteIletrado) {
      if (!dadosIletrado.nomeARogo || !dadosIletrado.cpfARogo || !dadosIletrado.nomeARogoTestemunha || !dadosIletrado.cpfARogoTestemunha) {
        setToastMessage("Por favor, preencha todos os dados do cliente iletrado.");
        return;
      }

      const cpfARogoNumeros = dadosIletrado.cpfARogo.replace(/\D/g, '');
      const cpfTestemunhaNumeros = dadosIletrado.cpfARogoTestemunha.replace(/\D/g, '');

      if (!validarCPF(cpfARogoNumeros)) {
        setToastMessage("CPF a Rogo inválido.");
        return;
      }
      if (!validarCPF(cpfTestemunhaNumeros)) {
        setToastMessage("CPF da Testemunha inválido.");
        return;
      }
    }

    setLoading(true);

    const celularPayload = bankTyping === "c6"
      ? dadosBancarios.celular.replace(/\D/g, '')
      : dadosBancarios.celular;

    if (bankTyping === "c6") {
      try {
        const token = localStorage.getItem('token');
        const dataToSend: {
          idSimulacao: string;
          cpf: string;
          numeroBeneficio: string;
          dataNascimento: string;
          codBanco: string;
          numeroAgencia: string;
          numeroConta: string;
          tipoConta: string;
          celular: string;
          nomeARogo?: string;
          cpfARogo?: string;
          nomeARogoTestemunha?: string;
          cpfARogoTestemunha?: string;
        } = {
          idSimulacao: selectedSimulacao.idSimulacao,
          cpf: dadosCliente.cpf,
          numeroBeneficio: dadosCliente.numeroBeneficio,
          dataNascimento: dadosCliente.dataNascimento,
          codBanco: formatBankCode(dadosBancarios.banco.split(' - ')[1]),
          numeroAgencia: dadosBancarios.agencia,
          numeroConta: dadosBancarios.conta,
          tipoConta: dadosBancarios.tipoConta,
          celular: celularPayload,
        };
    
        if (clienteIletrado) {
          dataToSend.nomeARogo = dadosIletrado.nomeARogo;
          dataToSend.cpfARogo = dadosIletrado.cpfARogo.replace(/\D/g, '');
          dataToSend.nomeARogoTestemunha = dadosIletrado.nomeARogoTestemunha;
          dataToSend.cpfARogoTestemunha = dadosIletrado.cpfARogoTestemunha.replace(/\D/g, '');
        }
    
        await axios.post(
          'https://acelereai.gfttech.com.br/api/v2/banking-services/c6/proposal/simple-digitation',
          dataToSend,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        alert("Proposta encaminhada para digitação, favor acompanhe");
        onSave();
      } catch (error: unknown) {
        console.error("Erro ao enviar digitação para C6:", error);
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          setToastMessage(
            (axiosError.response?.data as any)?.message ||
            "Erro ao enviar digitação para C6. Por favor, tente novamente."
          );
        } else {
          setToastMessage("Erro inesperado ao enviar digitação para C6. Por favor, tente novamente.");
        }
      } finally {
        setLoading(false);
      }
    } else if (bankTyping === "facta") {
      try {
        const token = localStorage.getItem('token');
        const dataToSend: {
          idSimulacao: string;
          cpf: string;
          numeroBeneficio: string;
          codBanco: string;
          numeroAgencia: string;
          numeroConta: string;
          tipoConta: string;
          celular: string;
          clienteIletrado: 'S' | 'N';
          nomeARogo?: string;
          cpfARogo?: string;
          nomeARogoTestemunha?: string;
          cpfARogoTestemunha?: string;
        } = {
          idSimulacao: selectedSimulacao.idSimulacao,
          cpf: dadosCliente.cpf,
          numeroBeneficio: dadosCliente.numeroBeneficio,
          codBanco: formatBankCode(dadosBancarios.banco.split(' - ')[1]),
          numeroAgencia: dadosBancarios.agencia,
          numeroConta: dadosBancarios.conta,
          tipoConta: dadosBancarios.tipoConta,
          celular: celularPayload,
          clienteIletrado: clienteIletrado ? 'S' : 'N', // Sempre enviar o campo clienteIletrado
        };
    
        // Adiciona campos relacionados ao cliente iletrado apenas se clienteIletrado for true
        if (clienteIletrado) {
          dataToSend.nomeARogo = dadosIletrado.nomeARogo;
          dataToSend.cpfARogo = dadosIletrado.cpfARogo.replace(/\D/g, '');
          dataToSend.nomeARogoTestemunha = dadosIletrado.nomeARogoTestemunha;
          dataToSend.cpfARogoTestemunha = dadosIletrado.cpfARogoTestemunha.replace(/\D/g, '');
        }
    
        await axios.post(
          'https://acelereai.gfttech.com.br/api/v2/banking-services/facta/proposal/digitation/simple',
          dataToSend,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        alert("Proposta encaminhada para digitação, favor acompanhe");
        onSave();
      } catch (error: unknown) {
        console.error("Erro ao enviar digitação:", error);
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            setToastMessage((axiosError.response.data as any).message || "Erro ao enviar digitação. Por favor, tente novamente.");
          } else {
            setToastMessage("Erro ao enviar digitação. Por favor, tente novamente.");
          }
        } else {
          setToastMessage("Erro ao enviar digitação. Por favor, tente novamente.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get('https://brasilapi.com.br/api/banks/v1');
        setBancos(response.data);
      } catch (error: any) {
        console.error("Erro ao buscar bancos:", error);
        setToastMessage("Erro ao buscar lista de bancos. Por favor, tente novamente mais tarde.");
      }
    };

    fetchBanks();
  }, []);

  return (
    <Modal show={show} className={styles.modal} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Confirme os dados da operação</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${styles.modalBody} ${styles.modalContent}`}>
        <Form.Group controlId="selectSimulation">
          <Form.Label>Escolha uma simulação:</Form.Label>
          <Select
            options={simulacoes.map(sim => ({
              value: sim.idSimulacao,
              label: sim.descricaoTabela
            }))}
            onChange={handleSelectChange}
            placeholder="Selecione uma simulação"
          />
        </Form.Group>

        {selectedSimulacao && (
          <div className={styles.simulationDetails}>
            <h5 className="mt-4">Detalhes da Operação</h5>
            <Row className="mt-3">
              <Col md={6}>
                <p><strong>Valor Bruto:</strong> R$ {formatCurrency(selectedSimulacao.valorLiquido)}</p>
                <p><strong>Valor Líquido:</strong> R$ {formatCurrency(selectedSimulacao.valorLiquido)}</p>
                <p><strong>Valor Cliente:</strong> R$ {formatCurrency(selectedSimulacao.valorCliente)}</p>
              </Col>
              <Col md={6}>
                <p><strong>Valor Parcela:</strong> R$ {formatCurrency(selectedSimulacao.valorParcela)}</p>
                <p><strong>Parcelas:</strong> {selectedSimulacao.parcelas}</p>
                <p><strong>Taxa Mensal Cliente:</strong> {selectedSimulacao.taxaMensalCliente.toFixed(2)}%</p>
              </Col>
            </Row>
          </div>
        )}
        
        <div className={styles.illiterateClient}>
          <Form.Check
            className="mt-3"
            type="switch"
            id="custom-switch"
            label="Cliente Não Alfabetizado"
            checked={clienteIletrado}
            onChange={(e: any) => setClienteIletrado(e.target.checked)}
          />

          {clienteIletrado && (
            <>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="nomeARogo">
                    <Form.Label>Nome a Rogo</Form.Label>
                    <Form.Control
                      type="text"
                      name="nomeARogo"
                      value={dadosIletrado.nomeARogo}
                      onChange={(e: any) => handleDadosIletradoChange(e)}
                      placeholder="Digite o Nome a Rogo"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="cpfARogo">
                    <Form.Label>CPF a Rogo</Form.Label>
                    <Form.Control
                      type="text"
                      name="cpfARogo"
                      value={dadosIletrado.cpfARogo}
                      onChange={(e: any) => handleDadosIletradoChange(e)}
                      placeholder="Digite o CPF a Rogo"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="nomeARogoTestemunha">
                    <Form.Label>Nome a Rogo Testemunha</Form.Label>
                    <Form.Control
                      type="text"
                      name="nomeARogoTestemunha"
                      value={dadosIletrado.nomeARogoTestemunha}
                      onChange={(e: any) => handleDadosIletradoChange(e)}
                      placeholder="Digite o Nome a Rogo Testemunha"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="cpfARogoTestemunha">
                    <Form.Label>CPF a Rogo Testemunha</Form.Label>
                    <Form.Control
                      type="text"
                      name="cpfARogoTestemunha"
                      value={dadosIletrado.cpfARogoTestemunha}
                      onChange={(e: any) => handleDadosIletradoChange(e)}
                      placeholder="Digite o CPF a Rogo Testemunha"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </div>

        <div className={styles.bankDetails}>
          <h5 className="mt-4">Dados Bancários do Cliente</h5>

          <Row className="mt-3">
            <Col md={12}>
              <Form.Group controlId="selectBank">
                <Form.Label>Banco</Form.Label>
                <Select
                  options={bancos.map(bank => ({
                    value: bank.code,
                    label: `${bank.name} - ${bank.code}`
                  }))}
                  onChange={(option) => setDadosBancarios(prevState => ({ ...prevState, banco: option?.label || "" }))}
                  placeholder="Selecione um banco"
                  isSearchable
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="bankAgency">
                <Form.Label>Agência</Form.Label>
                <Form.Control
                  type="text"
                  name="agencia"
                  value={dadosBancarios.agencia}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(e)}
                  placeholder="Digite a agência"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="bankAccount">
                <Form.Label>Conta</Form.Label>
                <Form.Control
                  type="text"
                  name="conta"
                  value={dadosBancarios.conta}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(e)}
                  placeholder="Digite a conta"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="selectAccountType">
                <Form.Label>Tipo de Conta</Form.Label>
                <Select
                  options={ bankTyping === "c6" ? tipoContasC6 : tipoContasFacta}
                  onChange={(option) => setDadosBancarios(prevState => ({ ...prevState, tipoConta: option?.value || "" }))}
                  placeholder="Selecione um tipo de conta"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="cellphone">
                <Form.Label>Celular</Form.Label>
                <Form.Control
                  type="text"
                  name="celular"
                  value={dadosBancarios.celular}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(e)}
                  placeholder="Digite o número do celular"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.modalFooter}>
        <ButtonPrimary
          content={loading ? "Carregando..." : "Enviar Digitação"}
          onClick={handleSave}
          disabled={
            !selectedSimulacao ||
            !dadosBancarios.agencia ||
            !dadosBancarios.conta ||
            !dadosBancarios.banco ||
            !dadosBancarios.tipoConta ||
            !dadosBancarios.celular ||
            (clienteIletrado && (!dadosIletrado.nomeARogo || !dadosIletrado.cpfARogo || !dadosIletrado.nomeARogoTestemunha || !dadosIletrado.cpfARogoTestemunha)) ||
            loading
          }
        />
      </Modal.Footer>
      <ToastWarning message={toastMessage} />
    </Modal>
  );
};

export default SimpleDigitationModal;
