import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Badge } from "react-bootstrap";
import { RecordData } from "../../../../interfaces/VirtualWallet";
import styles from './VirtualWallet.module.scss'
import { formatDate, renderBankName } from "../../../../Utils";
import { faDollar, faMoneyBill1Wave, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonGrayUltimate from "../../../Buttons/ButtonGrayUltimate";
import ConfirmRedirectTypingModal from "../../ReturnSimulation";
import SimpleDigitationModal from "../../SimpleDigitation";
import axios from "axios";
import ButtonTyping from "../../../Buttons/ButtonTyping";

interface LotDetailsModalProps {
  show: boolean;
  onHide: () => void;
  selectedLot: RecordData[];
}

const LotDetailsModal: React.FC<LotDetailsModalProps> = ({ show, onHide, selectedLot }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState<string | null>(null);
  const [showDigitationModal, setShowDigitationModal] = useState(false);
  const [selectedSimulation, setSelectedSimulation] = useState<RecordData | null>(null);
  const [birthDate, setBirthDate] = useState<string | null>(null);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value);
  };

  const fetchBirthDate = async (cpf: string) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://acelereai.gfttech.com.br/api/v1/marketplace/benefits?cpf=${cpf}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data && response.data[0] && response.data[0].beneficio) {
        setBirthDate(response.data[0].beneficio.dataNascimento);
      }
    } catch (error) {
      console.error("Erro ao buscar data de nascimento:", error);
      setBirthDate(null); // Em caso de erro, seta para null
    }
  };

  const handleOpenConfirmModal = async (bank: string, simulation: RecordData) => {
    setSelectedBank(bank);
    setSelectedSimulation(simulation);
    await fetchBirthDate(simulation.cpf);  // Busca a data de nascimento antes de abrir o modal
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setSelectedBank(null);
    setSelectedSimulation(null);
    setBirthDate(null); // Limpa a data de nascimento ao fechar o modal de confirmação
  };

  const handleOpenDigitationModal = () => {
    setShowDigitationModal(true);
  };

  return (
    <Modal show={show} size="lg" onHide={onHide} className={styles.lotDetailsModal}>
      <Modal.Header closeButton>
        <Modal.Title>Condições Contrato</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-end mb-3">
          <Badge bg="secondary">
            Condições Simuladas em: {formatDate(selectedLot[0]?.dataSimulacao)}
          </Badge>
        </div>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Banco</th>
              <th>Tabela</th>
              <th>Prazo</th>
              <th>Bruto</th>
              <th>Liquido</th>
              {selectedLot.some(record => record.banco === "c6") && <th>Digitar</th>}
            </tr>
          </thead>
          <tbody>
            {selectedLot.map((record, index) => (
              <tr key={index}>
                <td>{renderBankName(record.banco)}</td>
                <td>{record.descricaoTabela}</td>
                <td>{record.parcelas}</td>
                <td>{formatCurrency(record.valorLiquido)}</td>
                <td>{formatCurrency(record.valorCliente)}</td>
                {record.banco === "c6" && (
                  <td>
                    <ButtonTyping
                      content={<FontAwesomeIcon icon={faDollar}/>}
                      onClick={() => handleOpenConfirmModal(record.banco, record)}
                    />
                  </td>
                )}
                {record.banco !== "c6" && <td></td>}
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
      {showConfirmModal && selectedSimulation && (
        <ConfirmRedirectTypingModal
          show={showConfirmModal} 
          onHide={handleCloseConfirmModal} 
          bankTyping={selectedBank}
          numberContract={selectedSimulation.contrato}
          onSave={handleOpenDigitationModal}
        />
      )}
      
      {showDigitationModal && selectedSimulation && (
        <SimpleDigitationModal
          show={showDigitationModal}
          onHide={() => setShowDigitationModal(false)}
          idSimulation={selectedSimulation._id}
          bankTyping={selectedSimulation.banco}
          onSave={() => {
            // Lógica para salvar dados no modal de digitação
            setShowDigitationModal(false);
          }}
          dadosCliente={{
            cpf: selectedSimulation.cpf,
            numeroBeneficio: selectedSimulation.beneficio,
            dataNascimento: birthDate || "" // Passa a data de nascimento obtida ou string vazia se não obtida
          }}
          numberContract={selectedSimulation.contrato}
        />
      )}
    </Modal>
  );
};

export default LotDetailsModal;