import React, { useState } from "react";
import { Alert, Container } from "react-bootstrap";
import axios from "axios";
import styles from "./SimuladorPort.module.scss";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";
import { Step3 } from "./components/Step3";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import ToastWarning from "../ToastWarning";

const SimulatorPort: React.FC = () => {
  const [step, setStep] = useState(1);
  const [cpf, setCpf] = useState("");
  const [contracts, setContracts] = useState<any[]>([]);
  const [selectedContract, setSelectedContract] = useState<any>(null);
  const [beneficiaryData, setBeneficiaryData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleStep1Next = (cpf: string) => {
    setCpf(cpf);
    setLoading(true);
    setError("");
  
    axios
      .get(`https://acelereai.gfttech.com.br/api/v1/marketplace/benefits?cpf=${cpf}`)
      .then((response) => {
        if (response.status !== 200) throw new Error("Erro ao buscar dados do CPF");
        const data = response.data;
        if (data.length === 0) throw new Error("Nenhum benefício encontrado para este CPF");
  
        setBeneficiaryData(data[0].beneficio);
        setContracts(data[0].beneficio.contratosEmprestimo);
        setStep(2);
      })
      .catch((err) => {
        // Acessa a propriedade "message" do objeto de erro retornado pela API
        const errorMessage = err.response?.data?.message || "Erro ao processar a requisição.";
        setError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleStep2Next = (contractId: string) => {
    const contract = contracts.find((c) => c.contrato === contractId);
    setSelectedContract(contract);
    setStep(3);
  };

  const handleStep3Simulate = (data: any) => {
    alert("Simulação realizada com sucesso!");
  };

  return (
    <Container className={`${styles.container} mt-4`}>
      {error && <ToastWarning message={error} />}
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={step}
          timeout={300}
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
        >
          <div>
            {step === 1 && <Step1 onNext={handleStep1Next} loading={loading} />}
            {step === 2 && <Step2 contracts={contracts} onNext={handleStep2Next} />}
            {step === 3 && (
              <Step3
                contractDetails={selectedContract}
                beneficiaryData={beneficiaryData}
                onSimulate={handleStep3Simulate}
              />
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
};

export default SimulatorPort;