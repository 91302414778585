import React, { useState } from "react";
import { Form, Spinner, Alert } from "react-bootstrap";
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import styles from "../SimuladorPort.module.scss";
import { maskCpf } from "../../../Utils/mask";

interface Step1Props {
  onNext: (cpf: string) => void;
  loading: boolean;
}

export const Step1: React.FC<Step1Props> = ({ onNext, loading }) => {
  const [cpf, setCpf] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!cpf || cpf.length !== 14) {
      setError("Por favor, insira um CPF válido.");
      return;
    }
    onNext(cpf.replace(/\D/g, ""));
  };

  return (
    <Form className={`${styles.step} p-4 border rounded shadow-sm bg-light`}>
      <Form.Group className="mb-3">
        <Form.Label>CPF</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite o CPF"
          value={cpf}
          onChange={(e) => setCpf(maskCpf(e.target.value))}
          required
        />
      </Form.Group>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="d-flex justify-content-center">
        <ButtonPrimary
          disabled={loading}
          onClick={handleSubmit}
          content={loading ? <Spinner animation="border" size="sm" /> : "Próximo"}
        />
      </div>
    </Form>
  );
};