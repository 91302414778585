import { Container } from "react-bootstrap";
import { useState } from "react";
import styles from './SimulatorPort.module.scss';
import Logo from "../../../components/Logo";
import NavBarPortability from "../../../components/NavBar/Portability";
import PageTitle from "../../../components/PageTitle";
import PageSubTitle from "../../../components/PageSubTitle";
import SimulatorComponent from "../../../components/SimulatorPort";

export default function SimulatorPortability() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBarPortability onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      

      <Container className={menuOpen ? styles.sideBarOpen : styles.sideBarClose}>
        <div className={`${styles.titlesSection} mt-5`}>
          <div className={`${styles.titleSection}`}>
            <PageTitle size="h2">Simulador de Portabilidade</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-5`}>
            <PageSubTitle size='h5'>Verifique as condições para oferta de portabilidade.</PageSubTitle>
          </div>
        </div>

        <div className={styles.content}>
          <SimulatorComponent/>
        </div>
      </Container>
    </>
  );
}
