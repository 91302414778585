import React, { useState } from "react";
import { Form, Alert, Spinner, Row, Col } from "react-bootstrap";
import Select from "react-select";
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import styles from "../SimuladorPort.module.scss";

interface Step3Props {
  contractDetails: any;
  beneficiaryData: any;
  onSimulate: (data: any) => void;
}

interface BankOption {
  value: string;
  label: string;
}

export const Step3: React.FC<Step3Props> = ({ contractDetails, beneficiaryData, onSimulate }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Estado para os campos editáveis
  const [formData, setFormData] = useState({
    contractId: contractDetails.contrato,
    balance: contractDetails.saldoQuitacao,
    openInstallments: contractDetails.quantidadeParcelasEmAberto,
    originalInstallment: contractDetails.valorParcela,
    desiredInstallment: contractDetails.valorParcela,
    bankDestination: null as BankOption | null,
  });

  // Dados mockados para o select de bancos
  const bankOptions: BankOption[] = [
    { value: "341", label: "Banco Itaú" },
    { value: "001", label: "Banco do Brasil" },
    { value: "237", label: "Banco Bradesco" },
    { value: "104", label: "Caixa Econômica Federal" },
    { value: "033", label: "Banco Santander" },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption: BankOption | null) => {
    setFormData({ ...formData, bankDestination: selectedOption });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      // Monta o payload com os dados do formulário
      const payload = {
        ...formData,
        bankDestination: formData.bankDestination?.value,
      };
      onSimulate(payload);
    } catch (err) {
      setError("Erro ao realizar a simulação.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={`${styles.step} p-4 border rounded shadow-sm bg-light`}>
      <h4 className="mb-4">Confirme os dados para simulação:</h4>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3">
            <Form.Label>Banco Destino</Form.Label>
            <Select
              options={bankOptions}
              value={formData.bankDestination}
              onChange={handleSelectChange}
              placeholder="Selecione o banco destino"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3">
            <Form.Label>ID do Contrato</Form.Label>
            <Form.Control
              type="text"
              name="contractId"
              value={formData.contractId}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Saldo</Form.Label>
            <Form.Control
              type="number"
              name="balance"
              value={formData.balance.toFixed(2)}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Parcelas em Aberto</Form.Label>
            <Form.Control
              type="number"
              name="openInstallments"
              value={formData.openInstallments}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Parcela Original</Form.Label>
            <Form.Control
              type="number"
              name="originalInstallment"
              value={formData.originalInstallment}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Parcela Desejável</Form.Label>
            <Form.Control
              type="number"
              name="desiredInstallment"
              value={formData.desiredInstallment}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
    
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="d-flex justify-content-center mt-4">
        <ButtonPrimary
          content={loading ? <Spinner animation="border" size="sm" /> : "Simular"}
          disabled={loading}
        />
      </div>
    </Form>
  );
};