import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import styles from "./Login.module.scss";
import axios from "axios";
import lero from "../../assets/images/lero_ok (2).png";
import aceleraLogoMain from "../../assets/images/acelera_logo.png";
import ButtonLogin from "../../components/Buttons/ButtonLogin";
import { BASE_URL } from "../../config";
import ResetPassword from "../../components/ResetPassword";

export default function Login() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [reset, setReset] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // State to handle loading

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => setLogin(event.target.value);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const isFormValid = () => login.trim() !== "" && password.trim() !== "";

  const handleLogin = async () => {
    setLoading(true); // Start loading when login starts
    try {
        // Enviar as credenciais de login para a API
        const loginResponse = await axios.post(`${BASE_URL}/logar`, {
            email: login,
            password: password,
        });

        // Verificar se o token de autorização foi retornado
        if (loginResponse.data.authorization) {
            localStorage.setItem("token", loginResponse.data.authorization);
            localStorage.setItem("isMenuOpen", "true");

            // Obter os dados do usuário logado
            const userResponse = await axios.get(
                `${BASE_URL}/usuario/perfil-usuario`,
                {
                    headers: {
                        Authorization: `Bearer ${loginResponse.data.authorization}`,
                    },
                }
            );
            const idUser = userResponse.data.Usuario.userId;
            localStorage.setItem('idUser', idUser);

            const defaultPassword = await axios.get(`${BASE_URL}/usuario/primeiro-acesso/${idUser}`,{
              headers: {
                Authorization: `Bearer ${loginResponse.data.authorization}`,
            },
            })
            if(defaultPassword.data.first_access === true){
               window.location.href = `/PrimeiroAcesso`;
            } else{
              const userType = userResponse.data.Usuario.userType;
  
              // Verificar o tipo de usuário
              if (userType === "Partner") {
                  const typePartnerId = userResponse.data.Usuario.partnerData?.type?.type_partner_id;
  
                  if (typePartnerId === 1) {
                      // Redirecionar para a página de agente
                      localStorage.setItem('typePartner', '1')
                      window.location.href = "/Agente/PainelGeral";
                  } else if (typePartnerId === 2) {
                      // Redirecionar para a página geral
                      localStorage.setItem('typePartner', '2')
                      window.location.href = "/PainelGeral";
                  } else {
                      setError("Tipo de parceiro desconhecido");
                  }
              } else if (userType === "Employee") {
                  const positionId = userResponse.data.Usuario.employeeData?.position?.position_id;
  
                  if (positionId === 7) {
                      // Redirecionar para a página de cadastro
                      window.location.href = "/HomeCadastro";
                  } else if (positionId === 2 || positionId === 3 || positionId === 4) {
                      // Redirecionar para a página de gerente/regional/superintendente
                      window.location.href = "/PainelGeral";
                  } else if (positionId === 12) {
                    // Redirecionar para a página de portabilidade
                    window.location.href = "/Portabilidade/Parametros";
                  }
                  else if (positionId === 11) {
                    // Redirecionar para a página inicial Apoio Corban
                    window.location.href = "/ApoioCorban/Dashboard";
                  }
                  else {
                      setError("Tipo de posição desconhecida");
                  }
              } else {
                  setError("Tipo de usuário desconhecido");
              }
            }

        } else {
            setError("Token de autorização não encontrado na resposta");
        }
    } catch (error) {
        // Mensagem de erro genérica para falha no login
        setError("Erro ao realizar login. Por favor, revisar as credenciais e tentar novamente.");
        console.error("Erro no login:", error);
    } finally {
        setLoading(false); // Stop loading after the request is completed
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isFormValid()) handleLogin();
  };

  return (
    <div className={`${styles.mainSection}`}>
      <div className={`${styles.mascotSection}`}>
        <img src={lero} alt="Mascote" className={styles.imageMascot} />
      </div>
      {reset ? (
        <ResetPassword/>
      ) : (
        <div className={`${styles.loginContainer} p-5`}>
          <img src={aceleraLogoMain} className="mb-5" alt="Logo" />
          <div className={`${styles.inputGroup} mt-5`}>
            <div className={`${styles.floatingLabel}`}>
              <input
                type="text"
                id="login"
                placeholder=" "
                value={login}
                onChange={handleLoginChange}
                className={`form-control ${styles.input}`}
              />
              <label htmlFor="login">Login</label>
            </div>
            <div className={`${styles.floatingLabel}`}>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder=" "
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyPress}
                className={`form-control ${styles.input}`}
              />
              <label htmlFor="password">Senha</label>
              <div className={`${styles.icon}`} onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </div>
            </div>
          </div>
          {error && <p className="text-danger text-center mt-4">{error}</p>}
          <div className="mt-5">
            <ButtonLogin 
              disabled={!isFormValid() || loading} 
              onClick={handleLogin} 
              content={loading ? (
                <div className="spinner-border text-light" style={{ width: '1.5rem', height: '1.5rem' }} role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              ) : "Entrar"} 
            />
          </div>
          <div className="row mt-4 text-center">
            <span className={`${styles.spanAccess}`} onClick={() => setReset(true)}>Esqueci minha senha</span>
          </div>
        </div>
      )}
    </div>
  );
}
