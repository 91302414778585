import { useState, useEffect } from 'react';
import NavBar from "../../../../components/NavBar";
import { Col, Container, Row } from "react-bootstrap";
import styled from 'styled-components';
import styles from './SelectBanksCovenant.module.scss';
import PageTitle from "../../../../components/PageTitle";
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import PageSubTitle from '../../../../components/PageSubTitle';
import { motion } from 'framer-motion';
import axios from 'axios';
import BankListSimulationCovenant from '../../Unitary/SelectBanksCovenant/BankListSimulationCovenant';
import Logo from '../../../../components/Logo';
import { URL_SIMULATIONS } from '../../../../config';

const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 1.25rem;
  height: 1.25rem;
  appearance: none;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background: #153B7F;
    border-radius: 0.15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #153B7F;
  }
`;

const CustomLabel = styled.label`
  font-size: 1.25rem;
  color: #153B7F;
  margin-left: 0.5rem;
  font-weight: bold;
  font-family: 'PlusJakartaSans-Light', sans-serif;
`;

export default function SelectBanksCovenant() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [banks, setBanks] = useState<any[]>([]);
  const [selectedBanks, setSelectedBanks] = useState<string[]>([]);
  const covenantId = localStorage.getItem('covenantId')

  useEffect(() => {
    // Atualiza o local storage sempre que selectedBanks mudar
    localStorage.setItem('selectedBanks', JSON.stringify(selectedBanks));
  }, [selectedBanks]);

  useEffect(() => {
    const fetchBanks = async () => {

      try {
        const response = await axios.get(
          `${URL_SIMULATIONS}/bank/find-by-covenant-id?covenantId=${covenantId}`
        );
        setBanks(response.data);
      } catch (error) {
        console.error("Erro ao buscar os bancos", error);
      }
    };

    fetchBanks();
  }, []);

  const handleSelectAllChange = () => {
    const allBankIds = banks.map(bank => bank.bankId);
    setSelectAll(prev => {
      const newSelectAll = !prev;
      setSelectedBanks(newSelectAll ? allBankIds : []);
      return newSelectAll;
    });
  };

  const handleBankSelection = (selected: string[]) => {
    setSelectedBanks(selected);
  };

  const handleProceed = () => {
    if (selectedBanks.length > 0) {
      window.location.href = '/ConsultaLote/Convenios/EnvioArquivo';
    }
  };

  const handleBack = () => {
    window.location.href = '/ConsultaLote/Convenios';
  };

  const isButtonDisabled = selectedBanks.length === 0;

  const subtitleText = "Selecione um ou mais bancos para realizar a sua consulta.";

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      

      <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
        <div className="mt-5 d-flex justify-content-between align-items-center">
          <PageTitle size="h2">Consulta em Lote</PageTitle>
          <div className="d-flex align-items-center">
            <CustomCheckbox
              id="selectAll"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <CustomLabel htmlFor="selectAll">Selecionar Todos</CustomLabel>
          </div>
        </div>
        <div className='mb-5'>
          <PageSubTitle size='h5'>{subtitleText}</PageSubTitle>
        </div>
        <Row className='mb-5'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <BankListSimulationCovenant
              isSelectAll={selectAll} 
              onSelectionChange={handleBankSelection} 
            />
          </motion.div>
        </Row>

        <Row className='mt-5'>
          <Col className='d-flex justify-content-start mt-5'>
            <ButtonPrimary
              content={<><FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span></>}
              onClick={handleBack}
            />
          </Col>
          <Col className='d-flex justify-content-end mt-5'>
            <ButtonPrimary 
              content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
              disabled={isButtonDisabled}
              onClick={handleProceed}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
