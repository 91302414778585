import React, { useState } from "react";
import { Col, Row, Table, Collapse, Spinner, Badge } from "react-bootstrap";
import styles from "./ResultTable.module.scss";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import ButtonGrayUltimate from "../../../Buttons/ButtonGrayUltimate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faMoneyBill1Wave, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import SearchField from "../../../Search/SearchField";
import { formatCurrency } from "../../../../Utils";

interface CovenantData {
  cpf: string;
  idConvenio: string;
  nomeConvenio: string;
  nomeExternoConvenio: string;
  margemAtualizada: boolean;
  url: string;
  error: boolean;
  message: string | boolean;
}

interface ResultTableProps {
  data: CovenantData[];
}

interface SimulationCondition {
  status: string;
  id: string | null;
  parcelas: number | null;
  valorParcela: number | null;
  valorLiquido: number | null;
  valorBruto: number | null;
  valorTroco: number | null;
  taxaCliente: number | null;
  produto: string | null;
  error: string | null;
}

interface SimulationData {
  idSimulacao: string;
  user: {
    id: number;
    email: string;
    type: string;
    isAgent: boolean;
  };
  dadosConvenio: {
    idConvenio: string;
    nomeConvenio: string;
    urlImagem: string;
  };
  cpf: string;
  condicoes: {
    [bankName: string]: SimulationCondition[];
  };
  margem: {
    numeroBeneficio: string | null;
    situacaoBeneficio: string | null;
    bloqueadoParaEmprestimo: boolean | null;
    margemDisponivel: number;
    margemDisponivelCartao: number;
    limiteDisponivelCartao: number;
    margemDisponivelRCC: number;
    limiteDisponivelRCC: number;
  };
  dataSimulacao: string;
}

export default function ResultTableSearchCovenant({ data }: ResultTableProps) {
  const [searchCpf, setSearchCpf] = useState("");
  const [clientData, setClientData] = useState<Record<string, { loading: boolean; simulationData: SimulationData | null }>>({});
  const [openRow, setOpenRow] = useState<string | null>(null);

  const filteredData = data.filter(
    (item) =>
      item.nomeConvenio &&
      item.nomeConvenio.toLowerCase() !== "inss" &&
      item.cpf.toLowerCase().includes(searchCpf.toLowerCase())
  );

  const bankNameMapping: Record<string, string> = {
    "BANCO MASTER": "master",
    "BMG": "bmg",
    "C6": "c6",
    "PAN": "pan",
    "BCO DAYCOVAL S.A": "bco daycoval s.a",
    "QUERO + CREDITO": "quero + credito",
  };

  const handleOpportunityClick = async (cpf: string, idConvenio: string, nomeConvenio: string) => {
    if (openRow === cpf) {
      setOpenRow(null);
      return;
    }

    if (clientData[cpf]) {
      setOpenRow(cpf);
      return;
    }

    setClientData((prev) => ({
      ...prev,
      [cpf]: { loading: true, simulationData: null },
    }));
    setOpenRow(cpf);

    try {
      const token = localStorage.getItem("token");

      const banksResponse = await axios.get(
        `https://acelereai.gfttech.com.br/api/v1/bank/find-by-covenant-id?covenantId=${idConvenio}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const banks = banksResponse.data;
      const mappedBanks = banks
        .map((bank: any) => bankNameMapping[bank.bankName.toUpperCase()])
        .filter(Boolean);

      const body = {
        cpf,
        covenantId: idConvenio,
        covenantDescription: nomeConvenio,
        selectedBanks: mappedBanks,
      };

      const simulationResponse = await axios.post(
        "https://acelereai.gfttech.com.br/api/v2/engine/simulate",
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setClientData((prev) => ({
        ...prev,
        [cpf]: { loading: false, simulationData: simulationResponse.data },
      }));
    } catch (error) {
      console.error("Error handling opportunity click:", error);
      setClientData((prev) => ({
        ...prev,
        [cpf]: { loading: false, simulationData: null },
      }));
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axios.post(
        "https://webapi.gfttech.com.br/api/portal/createFile/excel",
        {
          fileName: "VALIDACAO_CONVENIOS_ACELEREAI",
          pageName: "ListagemConveniosClientes",
          pageHeader: ["CPF", "CONVÊNIO"],
          data: filteredData.map((item) => ({
            CPF: item.cpf,
            CONVENIO: item.nomeConvenio || "",
          })),
        },
        {
          responseType: "blob",
        }
      );

      const fileBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "validacao_convenios_acelereai.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <div className={styles.tableContainer}>
      <SearchField
        search={searchCpf}
        placeholder="Buscar por CPF"
        onSearchChange={(e) => setSearchCpf(e.target.value)}
      />

      <div className={styles.scrollableTable}>
        <Table className={`${styles.tableStyle} table table-hover`} bordered>
          <thead>
            <tr>
              <th></th>
              <th>CONVÊNIO</th>
              <th>CPF</th>
              <th>OPORTUNIDADE</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <React.Fragment key={item.cpf}>
                <tr>
                  <td>
                    <img
                      src={item.url}
                      alt={item.nomeConvenio}
                      style={{ width: "60px", height: "60px" }}
                    />
                  </td>
                  <td>{item.nomeConvenio}</td>
                  <td>{item.cpf}</td>
                  <td>
                    <ButtonGrayUltimate
                      content={
                        clientData[item.cpf]?.loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : openRow === item.cpf ? (
                          <FontAwesomeIcon icon={faChevronUp} />
                        ) : (
                          <FontAwesomeIcon icon={faChevronDown} />
                        )
                      }
                      onClick={() =>
                        handleOpportunityClick(item.cpf, item.idConvenio, item.nomeConvenio)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <Collapse in={openRow === item.cpf}>
                      <div>
                        {clientData[item.cpf]?.simulationData && (
                          <>
                            <Table striped bordered hover className="mt-3">
                              <thead>
                                <tr>
                                  <th>Banco</th>
                                  <th>Condição</th>
                                  <th>Valor</th>
                                  <th>Produto</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.entries(clientData[item.cpf]?.simulationData?.condicoes || {}).map(
                                  ([bankName, conditions]) =>
                                    Array.isArray(conditions) && conditions.length > 0 ? (
                                      conditions
                                        .filter((condition: SimulationCondition) => condition.status !== "failed")
                                        .map((condition: SimulationCondition, i: number) => (
                                          <tr key={i}>
                                            <td>{bankName}</td>
                                            <td>
                                              {condition.parcelas}x {formatCurrency((condition.valorParcela ?? 0).toString())}
                                            </td>
                                            <td>
                                              {condition.produto === 'REFINANCIAMENTO' 
                                                ? formatCurrency((condition.valorTroco ?? 0).toString()) 
                                                : formatCurrency((condition.valorLiquido ?? 0).toString())}
                                            </td>
                                            <td>{condition.produto}</td>
                                          </tr>
                                        ))
                                    ) : (
                                      <tr key={bankName}>
                                        <td colSpan={4}>Sem condições disponíveis para este banco.</td>
                                      </tr>
                                    )
                                )}
                              </tbody>
                            </Table>
                          </>
                        )}
                      </div>
                    </Collapse>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>

      <Row>
        <Col className="d-flex justify-content-end mt-4">
          <ButtonPrimary
            content={<FontAwesomeIcon icon={faDownload} />}
            onClick={handleDownload}
          />
        </Col>
      </Row>
    </div>
  );
}