import { Col, Container, Row } from "react-bootstrap";
import styles from '../../../../Query/BatchQuery/ConvenantSelectionBatch/ConvenantSelectionBatch.module.scss';
import { useState } from "react";
import { motion } from 'framer-motion';
import PageTitle from "../../../../../components/PageTitle";
import PageSubTitle from "../../../../../components/PageSubTitle";
import CovenantList from "../../CovenantList";
import ButtonPrimary from "../../../../../components/Buttons/ButtonPrimary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ToastValidationSimulation from "../../../../../components/ToastValidationSimulation";
import Logo from "../../../../../components/Logo";
import NavBarAgent from "../../../../../components/NavBar/Agent_Partner";

export default function SelectCovenantUnitaryAgent(){
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedCovenant, setSelectedCovenant] = useState<string | null>(null);
  const subtitleTextClient = "Escolha um convênio para prosseguir com a simulação.";
  localStorage.removeItem("bankId")
  localStorage.removeItem("selectedBankNames")
  localStorage.removeItem("selectedBanks")
  localStorage.removeItem("selectedProductId")
  localStorage.removeItem("selectedProductDescription")
  localStorage.removeItem("processedCpfs")

  const onSelectionChange = (covenantId: string) => {
    setSelectedCovenant(covenantId);
  }

  const handleSubmit = () => {
    if (!selectedCovenant) {
      setToastMessage('Por favor, selecione um convênio antes de prosseguir.');
      return;
    }
    
    // Se um convênio foi selecionado, redireciona para a próxima página
    window.location.href = "/Agente/ConsultaUnitaria/Convenios/SelecaoBancos";
  }

  const buttonDisable = () => {
    return !selectedCovenant;
  }

  return(
    <>
      <section className={styles.navbarSection}>
        <NavBarAgent onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titlesSection} mt-5`}>
          <div className={`${styles.titleSection}`}>
            <PageTitle size="h4">Selecione o Convênio</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-5`}>
            <PageSubTitle size='h6'>{subtitleTextClient}</PageSubTitle>
          </div>
        </div>

        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <CovenantList onSelectionChange={onSelectionChange} />    
          <Row>
            <Col className='d-flex justify-content-end mt-4'>
              <ButtonPrimary 
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                onClick={handleSubmit}
                disabled={buttonDisable()} 
              />
            </Col>
          </Row>
        </Container>
      </motion.div>
      
      <ToastValidationSimulation message={toastMessage} />
    </>
  )
}
