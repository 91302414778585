import React, { useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import styles from './ChoosePeriodExport.module.scss'
import ButtonPrimary from "../../../Buttons/ButtonPrimary";

interface PeriodModalProps {
  show: boolean;
  onHide: () => void;
  onSave: (startDate: string, endDate: string) => void;
}

const PeriodModal: React.FC<PeriodModalProps> = ({ show, onHide, onSave }) => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleSave = () => {
    if (startDate && endDate) {
      onSave(startDate, endDate);
      onHide();
    } else {
      alert("Por favor, selecione ambas as datas.");
    }
  };

  return (
    <Modal show={show} className={styles.modal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Selecione o Período</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group controlId="startDate">
                <Form.Label>Data Inicial</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="endDate">
                <Form.Label>Data Final</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary 
          content="Exportar"
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default PeriodModal;