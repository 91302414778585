import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from '../Factor.module.scss'

interface CreateFactorBatchodalPMrops {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
}

const CreateFactorBatchModal: React.FC<CreateFactorBatchodalPMrops> = ({ show, onHide, onSave }) => {


  const handleSave = async () => {
    
  };

  return (
    <Modal show={show} onHide={onHide} className={styles.contentGeneral}>
      <Modal.Header closeButton>
        <Modal.Title>Criar Fatores em Lote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formFile" className="mt-3 mb-3">
          <Form.Control type="file" />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateFactorBatchModal;