import React, { useState } from "react";
import { Form, Alert, Table } from "react-bootstrap";
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import styles from "../SimuladorPort.module.scss";

interface Contract {
  contrato: string;
  banco: { nome: string };
  valorParcela: number;
  quantidadeParcelas: number;
  quantidadeParcelasEmAberto: number;
  valorEmprestado: number;
  saldoQuitacao: number;
}

interface Step2Props {
  contracts: Contract[];
  onNext: (contractId: string) => void;
}

export const Step2: React.FC<Step2Props> = ({ contracts, onNext }) => {
  const [selectedContract, setSelectedContract] = useState<string | null>(null);
  const [error, setError] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedContract) {
      setError("Selecione um contrato para continuar.");
      return;
    }
    onNext(selectedContract);
  };

  return (
    <Form className={`${styles.step} p-4 border rounded shadow-sm bg-light`}>
      <h5 className="mb-4">Selecione um contrato para simular a portabilidade:</h5>
      <div className={styles.tableContainer}>
        <Table className={styles.table} striped bordered hover>
          <thead>
            <tr>
              <th>Banco</th>
              <th>PMT</th>
              <th>Prazo</th>
              <th>Em Aberto</th>
              <th>Valor Emprestado</th>
              <th>Saldo de Quitação</th>
              <th>Selecionar</th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((contract) => (
              <tr key={contract.contrato}>
                <td>{contract.banco.nome}</td>
                <td>{contract.valorParcela.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td>{contract.quantidadeParcelas}</td>
                <td>{contract.quantidadeParcelasEmAberto}</td>
                <td>{contract.valorEmprestado.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td>{contract.saldoQuitacao.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td>
                  <Form.Check
                    type="radio"
                    name="contract"
                    onChange={() => setSelectedContract(contract.contrato)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="d-flex justify-content-center mt-4">
        <ButtonPrimary content="Próximo" onClick={handleSubmit} />
      </div>
    </Form>
  );
};