import { Modal } from "react-bootstrap";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import ButtonGray from "../../../Buttons/ButtonGray";
import { BASE_URL } from "../../../../config";
import { useState } from "react";
import ToastWarning from "../../../ToastWarning";

interface ModalProps{
    showModal: boolean;
    onClose: () => void;
    id: number;
}

const InactiveModal = ({showModal, onClose, id}: ModalProps) => {

    const [toastMessage, setToastMessage] = useState<string>('');

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');

        try{
            const res = await fetch(`https://webservicesstaging.gfttech.com.br/api/v1/parceiro/desligar-partner/${id}`,{
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({inactivate: true}),
            });

            if (res.ok){
                setToastMessage("Acesso inativado com sucesso!")
            } 
        } catch(e){
            setToastMessage("Erro ao inativar acesso!");
            throw e;
        }

        setTimeout(() => {
            setToastMessage('');
            onClose();
        }, 3000);
    }

    return(
        <Modal show={showModal} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Inativar - Usuário {id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               
            </Modal.Body>
            <Modal.Footer>
                <ButtonPrimary onClick={handleSubmit} content="Confirmar"/>
                <ButtonGray onClick={onClose} content="Cancelar"/>
            </Modal.Footer>
            {toastMessage && <ToastWarning message={toastMessage}/>}
        </Modal>
    )
}

export default InactiveModal;