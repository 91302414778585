import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner, Alert, Table } from "react-bootstrap";
import styles from "./ResumeClient.module.scss";
import { BeneficioResponse, ContratoEmprestimo, ContratoCartao } from "../../../../interfaces/ConsultaBeneficioCR";
import { formatDate } from "../../../../Utils";

interface ResumeClientModalProps {
  show: boolean;
  onHide: () => void;
  selectedLot: { cpf: string }[];
}

const ResumeClientModal: React.FC<ResumeClientModalProps> = ({ show, onHide, selectedLot }) => {
  const cpfCliente = selectedLot[0]?.cpf || "";
  const [benefitsData, setBenefitsData] = useState<BeneficioResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const fetchBenefits = async () => {
    if (!cpfCliente) return;
    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        `https://acelereai.gfttech.com.br/api/v1/marketplace/benefits?cpf=${cpfCliente}`
      );

      if (!response.ok) {
        throw new Error("Erro ao buscar benefícios.");
      }

      const data: BeneficioResponse[] = await response.json();
      if (data && data.length > 0) {
        setBenefitsData(data[0]); // Aqui estamos pegando o primeiro item da lista
      } else {
        setError("Nenhum benefício encontrado.");
      }
    } catch (err) {
      console.error("Erro ao buscar benefícios:", err);
      setError("Erro ao buscar benefícios. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show && cpfCliente) {
      fetchBenefits();
    }
  }, [show, cpfCliente]);

  const formatCurrency = (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  const renderBankName = (bank: { nome: string }) => bank?.nome || "N/A";

  return (
  <Modal show={show} size="xl" onHide={onHide} className={styles.resumeClientModal}>
    <Modal.Header closeButton>
      <Modal.Title>Resumo do Cliente - {cpfCliente || "N/A"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {loading && <div className="text-center"><Spinner animation="border" /></div>}

      {error && (
        <Alert variant="danger" className="text-center">
          {error}
          <Button variant="outline-danger" size="sm" className="ms-2" onClick={fetchBenefits}>
            Tentar novamente
          </Button>
        </Alert>
      )}

      {benefitsData && (
      <>
          <div className={styles.headerClientInfo}>
            <div className={styles.infoItem}>
              <strong>Nome:</strong>
              <span className={styles.infoValue}>{benefitsData?.beneficio?.nome || "N/A"}</span>
            </div>
            <div className={styles.infoItem}>
              <strong>Benefício:</strong>
              <span className={styles.infoValue}>{benefitsData?.beneficio?.beneficio || "N/A"}</span>
            </div>
            <div className={styles.infoItem}>
              <strong>Data de Nascimento:</strong>
              <span className={styles.infoValue}>{formatDate(benefitsData?.beneficio?.dataNascimento || "N/A")}</span>
            </div>
            <div className={styles.infoItem}>
              <strong>Situação do Benefício:</strong>
              <span className={styles.infoValue}>{benefitsData?.beneficio?.situacaoBeneficio || "N/A"}</span>
            </div>
            <div className={styles.infoItem}>
              <strong>Espécie:</strong>
              <span className={styles.infoValue}>{benefitsData?.beneficio?.especie?.codigo} - {benefitsData?.beneficio?.especie?.descricao || ""}</span>
            </div>
            <div className={styles.infoItem}>
              <strong>Representante Legal:</strong>
              <span className={styles.infoValue}>{benefitsData?.beneficio?.possuiRepresentanteLegalProcurador ? "SIM" : "NÃO"}</span>
            </div>
          </div>


          <h5 className="mt-5">Contratos de Empréstimo</h5>
          <div className={styles.contractsTableWrapper}>
          <Table striped bordered hover responsive className="contracts-table">
            <thead>
              <tr>
                <th>Banco</th>
                <th>Valor Emprestado</th>
                <th>Parcelas</th>
                <th>Saldo Quitação</th>
                <th>Taxa</th>
              </tr>
            </thead>
            <tbody>
              {benefitsData?.beneficio?.contratosEmprestimo?.map((contract: ContratoEmprestimo, index: number) => (
                <tr key={index}>
                  <td>{renderBankName(contract.banco)}</td>
                  <td>{formatCurrency(contract.valorEmprestado)}</td>
                  <td>{contract.quantidadeParcelas} ({contract.quantidadeParcelasEmAberto} em aberto)</td>
                  <td>{formatCurrency(contract.saldoQuitacao)}</td>
                  <td>{contract.taxa}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <h5 className="mt-5">Contratos de Cartão</h5>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Banco</th>
              <th>Limite</th>
              <th>Reservado</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {benefitsData?.beneficio?.contratosCartao?.map((contract: ContratoCartao, index: number) => (
              <tr key={index}>
                <td>{renderBankName(contract.banco)}</td>
                <td>{formatCurrency(contract.limiteCartao)}</td>
                <td>{formatCurrency(contract.valorReservado)}</td>
                <td>{contract.tipoEmprestimo?.descricao}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>Fechar</Button>
    </Modal.Footer>
  </Modal>

  );
};

export default ResumeClientModal;