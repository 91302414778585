import { useEffect, useState } from "react";
import { Badge, ProgressBar, Table } from "react-bootstrap";
import styles from './BatchSimulations.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ButtonGrayUltimate from "../../../../components/Buttons/ButtonGrayUltimate";
import Pagination from '../../../../components/Pagination';
import FilterBatch from "./FilterBatch";
import ViewDataBatchSimulation from "../../../../components/Modals/Simulations/ViewDataBatchSimulation";
import ToastWarning from "../../../../components/ToastWarning";
import SpinnerDefault from "../../../../components/Spinner";
import { getBadgeVariant, getBadgeVariantSimulation, translateStatus, translateStatusSimulation } from "../../../../Utils";

interface SimulationData {
  idLoteSimulacao: string;
  numeroProtocolo: string;
  numeroCpfs: string;
  status: string;
  percentual: number;
  date: string;
}

export default function BatchSimulations() {
  const [simulations, setSimulations] = useState<SimulationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedBatchId, setSelectedBatchId] = useState<string>('');
  const [toastMessage, setToastMessage] = useState<string>('');
  const [filterParams, setFilterParams] = useState<{ dataInicio?: string; dataFim?: string; numeroProtocolo?: string }>({});

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchSimulations(pageNumber);
  };

  useEffect(() => {
    fetchSimulations(currentPage);
  }, [filterParams, currentPage]);

  const fetchSimulations = async (pageNumber = 1) => {
    try {
      setLoading(true);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token não encontrado');
      }

      // Chamada para a API de bulk-details com os filtros
      const bulkDetailsResponse = await fetch(`https://acelereai.gfttech.com.br/api/v2/engine/simulate/bulk-details`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...filterParams,
          page: pageNumber,
        })
      });

      if (!bulkDetailsResponse.ok) {
        throw new Error('Erro ao buscar detalhes do lote');
      }

      const bulkDetailsData = await bulkDetailsResponse.json();

      // Mapear os dados recebidos para o formato esperado
      const mappedSimulations = bulkDetailsData.data.map((item: any) => ({
        idLoteSimulacao: item.idLoteSimulacao || item.batchId,
        numeroProtocolo: item.numeroProtocolo || '',
        numeroCpfs: item.numeroCpfs || item.totalCpfs || 0,
        status: item.status || 'unknown',
        date: item.date,
        percentual: item.percentual || 0,
      }));

      setSimulations(mappedSimulations);
      setTotalItems(bulkDetailsData.totalItems);
      setTotalPages(bulkDetailsData.totalPage);
    } catch (error) {
      console.error('Erro ao buscar simulações:', error);
      setToastMessage('Erro ao buscar dados');
    } finally {
      setLoading(false);
    }
  };

  // Função para filtrar as simulações
  const handleFilter = (search: string, startDate: string, endDate: string) => {
    let params = {};

    // Filtro pelo número de protocolo
    if (search) {
      params = { ...params, numeroProtocolo: search };
    }

    // Filtro pela data inicial e final
    if (startDate && endDate) {
      params = { ...params, dataInicio: startDate, dataFim: endDate };
    } else if (startDate || endDate) {
      setToastMessage('Por favor, selecione tanto a data de início quanto a data final.');
      return;
    }

    setFilterParams(params);
    setCurrentPage(1);
  };

  const handleViewBatch = (batchId: string) => {
    setSelectedBatchId(batchId);
    setModalShow(true);
  };

  return (
    <>  
      <FilterBatch onFilter={handleFilter} />

      <div className={`${styles.tableContainer} mt-5`}>
        <div className={styles.scrollableTable}>
          <Table className={`${styles.tableStyle} table table-responsive`}>
            <thead>
              <tr>
                <th>PROTOCOLO</th>
                <th>DATA DO LOTE</th>
                <th>CLIENTES</th>
                <th>PROGRESSO</th>
                <th>STATUS</th>
                <th>AÇÕES</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={4}>
                    <SpinnerDefault />
                  </td>
                </tr>
              ) : simulations.map((simulation) => (
                <tr key={simulation.idLoteSimulacao}>
                  <td>{simulation.numeroProtocolo}</td>
                  <td>{new Date(simulation.date).toLocaleString('pt-BR')}</td>
                  <td>{simulation.numeroCpfs} clientes</td>
                  <td>
                    <div className={styles.progressWrapper}>
                      <ProgressBar 
                        striped
                        now={simulation.percentual || 0} 
                        variant="warning"
                        className={styles.customProgressBar}
                      />
                      <span className={styles.customLabel}>{simulation.percentual.toFixed(2)}%</span>
                    </div>
                  </td>
                  <td>
                    <Badge bg={getBadgeVariantSimulation(simulation.status)}>
                      {translateStatusSimulation(simulation.status)}
                    </Badge>
                  </td>
                  <td>
                    <ButtonGrayUltimate 
                      content={<><FontAwesomeIcon icon={faInfoCircle} /></>} 
                      onClick={() => handleViewBatch(simulation.idLoteSimulacao)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {totalPages > 1 && (
          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            
            onPageChange={handlePageChange}
          />
        )}
      </div>

      <ViewDataBatchSimulation 
        show={modalShow} 
        onHide={() => setModalShow(false)} 
        batchId={selectedBatchId} 
      />
      {toastMessage && <ToastWarning message={toastMessage} />}
    </>
  );
}
