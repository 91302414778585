import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styles from './ Card.module.scss';

interface CardProps {
  title: string;
  value: number;
  typeExibition: 'float' | 'int' | 'percent';
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ title, value, typeExibition, onClick }) => {
  const formattedValue =
    typeExibition === 'float'
      ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      : typeExibition === 'int'
      ? value.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      : value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%';

  return (
    <BootstrapCard
      className={`${styles.dashboardCard} ${onClick ? styles.clickable : ''}`}
      onClick={onClick}
      style={{ minWidth: '250px', cursor: onClick ? 'pointer' : 'default' }}
    >
      <BootstrapCard.Body className="d-flex justify-content-between align-items-center">
        <div>
          <BootstrapCard.Title className="fs-6 fw-bold text-muted">{title}</BootstrapCard.Title>
          <BootstrapCard.Text className="fs-4 fw-bold">{formattedValue}</BootstrapCard.Text>
        </div>
        {onClick && (
          <FontAwesomeIcon
            icon={faArrowRight}
            className={styles.redirectIcon}
            title="Ver análise detalhada"
            size="lg"
          />
        )}
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

export default Card;