import { ChangeEvent, useEffect, useState } from 'react';
import styles from './Registration.module.scss';
import { Container, Table } from 'react-bootstrap';
import PageTitle from '../../../components/PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCircleXmark, faEdit, faLockOpen, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import ButtonGrayUltimate from '../.././../components/Buttons/ButtonGrayUltimate';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import SpinnerDefault from '../../../components/Spinner';
import { BASE_URL } from '../../../config';
import { PaginationData, PartnerData } from './Register';
import Pagination from '../../../components/Pagination';
import EditRegistration from '../../../components/Modals/Registration/Edit';
import ResetPassWord from '../../../components/Modals/Registration/ResetPassword';
import SearchRegister from '../../../components/Search/SearchRegister';
import ExternalModal from '../../../components/Modals/Registration/External';
import AddRegistration from '../../../components/Modals/Registration/Add';
import Logo from '../../../components/Logo';
import NavBarApoioCorban from '../../../components/NavBar/ApoioCorban';
import InactiveModal from '../../../components/Modals/Registration/Inactive';

interface FormattedRows {
    partner_id: number;
    id: number;
    name: string;
    login: string;
    type: string;
    type_id: number | null;
    position: string;
}

export default function RegisterApoioCorban() {
    const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [showReset, setShowReset] = useState<boolean>(false);
    const [showExternal, setShowExternal] = useState<boolean>(false);
    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [rows, setRows] = useState<FormattedRows[]>([]);
    const [id, setId] = useState<number>(0);
    const [searchName, setSearchName] = useState<string>('');
    const [searchType, setSearchType] = useState<string>('');
    const [data, setData] = useState<PaginationData | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 5; 

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchName(e.target.value);
        setCurrentPage(1); 
    };

    const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSearchType(e.target.value || '');
        setCurrentPage(1); 
    };

    const handleAccess = async () => {
        const token = localStorage.getItem('token');

        try {
            setLoading(true);
            const res = await fetch(`${BASE_URL}/parceiro/buscar-todos?per_page=${itemsPerPage}&page=${currentPage}&name=${searchName}&type=${searchType}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
            });

            if (res.ok) {
                const data: PaginationData = await res.json();
                const rowData: PartnerData[] = data.data;

                const formattedData: FormattedRows[] = rowData.map((data) => ({
                    id: data.user.user_id,
                    login: data.user.email,
                    name: data.user.user_name,
                    type: data.type?.type || 'Não Identificado',
                    partner_id: data.partner_id,
                    type_id: data.type_partner_id,
                    position: data.position.position_name
                }));

                setRows(formattedData);
                setData(data);
            } else {
                console.error(res.statusText);
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleAccess();
    }, [showEdit, showModal, searchName, searchType, currentPage]);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleEdit = (row: number) => {
        setShowEdit(true);
        setId(row);
    };

    const handleReset = (row: number) => {
        setShowReset(true);
        setId(row);
    };

    const handleExternal = (row: number) => {
        setShowExternal(true);
        setId(row);
    };

    const handleInactive = (row: number) => {
        setShowInactive(true);
        setId(row);
    };

    const handleClose = () => {
        setShowEdit(false);
        setShowModal(false);
        setShowReset(false);
        setShowExternal(false);
        setShowInactive(false);
        setId(0);
    };

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'name', label: 'Nome' },
        { id: 'login', label: 'Login' },
        { id: 'type', label: 'Tipo' },
        { id: 'position', label: 'Cargo' },
        { id: '', label: 'Editar' },
        { id: '', label: 'Resetar' },
        { id: '', label: 'Externo' },
        { id: '', label: 'Inativar'}
    ];

    return (
        <>
            <section className={styles.navbarSection}>
                <NavBarApoioCorban onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
            </section>
            <Logo/>

            <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose} mt-5`}>
                <div className="d-flex justify-content-start mb-5">
                    <PageTitle size="h3">Cadastro de Usuários</PageTitle>
                </div>

                <SearchRegister
                    name={searchName}
                    type={searchType}
                    onSearchChange={handleSearchChange}
                    onTypeChange={handleTypeChange}
                />

                <div className={`${menuOpen ? '' : styles.close} table-container`}>
                    <div className="d-flex justify-content-end">
                        <ButtonPrimary content={<FontAwesomeIcon icon={faAdd} />} onClick={() => setShowModal(true)} />
                    </div>

                    {loading ? (
                        <div className="d-flex justify-content-center mt-3">
                            <SpinnerDefault />
                        </div>
                    ) : (
                        <Table className="table table-responsive">
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <th key={column.id}>{column.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row) => (
                                    <tr key={row.id}>
                                        <td>{row.id}</td>
                                        <td>{row.name}</td>
                                        <td>{row.login}</td>
                                        <td>{row.type}</td>
                                        <td>{row.position}</td>
                                        <td style={{ width: '10%' }}>
                                            <ButtonGrayUltimate
                                                content={<FontAwesomeIcon icon={faEdit} />}
                                                onClick={() => handleEdit(row.partner_id)}
                                            />
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            <ButtonGrayUltimate
                                                content={<FontAwesomeIcon icon={faLockOpen} />}
                                                onClick={() => handleReset(row.id)}
                                            />
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            <ButtonGrayUltimate
                                                content={<FontAwesomeIcon icon={faSquareArrowUpRight} />}
                                                onClick={() => handleExternal(row.id)}
                                            />
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            <ButtonGrayUltimate
                                                content={<FontAwesomeIcon icon={faCircleXmark} />}
                                                onClick={() => handleInactive(row.id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}

                    {(data?.last_page || 1) > 1 && (
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            onPageChange={handlePageChange}
                            totalItems={data?.total || 0}
                        />
                    )}
                </div>
            </Container>

            <AddRegistration onClose={handleClose} showModal={showModal} />
            <EditRegistration onClose={handleClose} showModal={showEdit} id={id} />
            <ResetPassWord id={id} onClose={handleClose} showModal={showReset} />
            <ExternalModal id={id} onClose={handleClose} showModal={showExternal}/>
            <InactiveModal showModal={showInactive} id={id} onClose={handleClose}/>

        </>
    );
}