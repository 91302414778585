import { Button, Container } from "react-bootstrap";
import { useState } from "react";
import styles from './SearchCovenant.module.scss';
import leroSearch from '../../../assets/images/lerocomduvida.png';
import SearchBatchTab from "../../../components/SearchCovenant/SearchBatchTab";
import PageTitle from "../../../components/PageTitle";
import PageSubTitle from "../../../components/PageSubTitle";
import NavBarAgent from "../../../components/NavBar/Agent_Partner";
import SearchUnitaryTabAgent from "../../../components/SearchCovenantAgent/SearchUnitaryTab";

export default function SearchCovenant() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [activeTab, setActiveTab] = useState<'unitaria' | 'lote'>('unitaria');
  const subtitleTextClient = "Verifique a qual Convênio pertence seu cliente."

  const handleTabChange = (tab: 'unitaria' | 'lote') => {
    setActiveTab(tab);
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBarAgent onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      

      <Container className={menuOpen ? styles.sideBarOpen : styles.sideBarClose}>
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h3">Selecione o Convênio</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-5`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <div className={`${styles.layoutContainer} mt-5`}>

          {/* Sidebar */}
          <aside className={`${styles.sidebar}`}>
            <div className={styles.profileContainer}>
              <img src={leroSearch} alt="Profile" className={styles.profileImage} />
            </div>
            <div className={styles.tabButtons}>
              <Button
                className={`${styles.tabButton} ${activeTab === 'unitaria' ? styles.active : ''}`}
                onClick={() => handleTabChange('unitaria')}
              >
                Unitária
              </Button>
              <Button
                className={`${styles.tabButton} ${activeTab === 'lote' ? styles.active : ''}`}
                onClick={() => handleTabChange('lote')}
              >
                Em Lote
              </Button>
            </div>
          </aside>

          {/* Linha Vertical */}
          <div className={styles.verticalLine}></div>

          {/* Conteúdo Principal */}
          <main className={styles.content}>
            {activeTab === 'unitaria' && <SearchUnitaryTabAgent />}
            {activeTab === 'lote' && <SearchBatchTab />}
          </main>
        </div>
      </Container>
    </>
  );
}
