import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import Pagination from './Pagination';
import CreateFactorModal from './CreateFactorModal';
import EditFactorModal from './EditFactorModal';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import ButtonGrayUltimate from '../../../../../components/Buttons/ButtonGrayUltimate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ButtonDelete from '../../../../../components/Buttons/ButtonDelete';
import DeleteFactorModal from './DeleteFactorModal';
import Factor from '../../../../../interfaces/Factor';
import ButtonYellow from '../../../../../components/Buttons/ButtonYellow';
import CreateFactorBatchModal from './CreateFactorBatchModal';
import styles from './Factor.module.scss'

const FactorList: React.FC = () => {
  const [factors, setFactors] = useState<Factor[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFactor, setSelectedFactor] = useState<Factor | null>(null);
  const [selectedFactorId, setSelectedFactorId] = useState<number | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCreateBatchModal, setShowCreateBatchModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false)

  const fetchFactors = async (page: number = 1) => {
    try {
      const response = await axios.get(`https://bi.gfttech.com.br/port/factor?page=${page}&pageSize=5`);
      console.log('lerolero', response.data);
      
      setFactors(response.data.data);
      setTotalPages(response.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error('Erro ao buscar fatores:', error);
    }
  };

  useEffect(() => {
    fetchFactors();
  }, []);

  const handleCreate = () => setShowCreateModal(true);
  const handleCreateBatch = () => setShowCreateBatchModal(true);

  const handleEdit = (factor: Factor) => {
    setSelectedFactor(factor);
    setShowEditModal(true);
  };
  const handleDelete = (factorId: number) => {
    setSelectedFactorId(factorId);
    setShowDeleteModal(true);
  };

  const handleSave = () => {
    fetchFactors(currentPage);
    setShowCreateModal(false);
    setShowEditModal(false);
  };

  const handleSaveBatch = () => {
    fetchFactors(currentPage);
    setShowCreateBatchModal(false);
    setShowEditModal(false);
  };
  const refreshFactors = () => fetchFactors(currentPage);

  const formatDate = (date: string): string => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return "";
    }
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className={`${styles.contentGeneral} mt-5`}>
      <Row className='mb-4'>
        <Col className='d-flex justify-content-start gap-3'>
          <ButtonPrimary onClick={handleCreate} content="+ Adicionar Fator Unitário" />
          <ButtonYellow onClick={handleCreateBatch} content="+ Adicionar Lote de Fatores" />
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Banco</th>
            <th>Data</th>
            <th>Tabela</th>
            <th>Valor</th>
            <th>Editar</th>
            <th>Excluir</th>
          </tr>
        </thead>
        <tbody>
          {factors.map((factor) => (
            <tr key={factor.id}>
              <td>{factor.id}</td>
              <td>{factor.bank?.bankName ?? `Banco Não Identificado`}</td>
              <td>{formatDate(factor.date)}</td>
              <td>{factor.table}</td>
              <td>{factor.value}</td>
              <td>
                <ButtonGrayUltimate
                  content={<FontAwesomeIcon icon={faEdit} />}
                  onClick={() => handleEdit(factor)}
                />
              </td>
              <td>
                <ButtonDelete
                  content={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => handleDelete(factor.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={fetchFactors}
      />
      <CreateFactorModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        onSave={handleSave}
      />
      <EditFactorModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        onSave={handleSave}
        factorToEdit={selectedFactor}
      />
      <DeleteFactorModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        factorId={selectedFactorId}
        onDelete={() => {
          refreshFactors();
          setShowDeleteModal(false);
        }}
      />
      <CreateFactorBatchModal
        show={showCreateBatchModal}
        onHide={() => setShowCreateBatchModal(false)}
        onSave={handleSaveBatch}
      />
    </div>
  );
};

export default FactorList;
