import React, { useState, useEffect, ChangeEvent } from "react";
import { Table, Spinner, Form, Row, Col, Badge, Alert } from "react-bootstrap";
import styles from "./VirtualWallet.module.scss";
import ButtonGrayUltimate from "../../../components/Buttons/ButtonGrayUltimate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faDownload, faList, faSearch } from "@fortawesome/free-solid-svg-icons";
import LotDetailsModal from "../../../components/Modals/VirtualWallet/Conditions";
import { RecordData } from "../../../interfaces/VirtualWallet";
import PageTitle from "../../../components/PageTitle";
import PageSubTitle from "../../../components/PageSubTitle";
import { formatCPF, formatCurrency, formatDate, renderBankName } from "../../../Utils";
import Pagination from '../../../components/Pagination';
import ButtonPrimary from "../../../components/Buttons/ButtonPrimary";
import Select from "react-select";
import ResumeClientModal from "../../../components/Modals/VirtualWallet/ResumeClient";
import PeriodModal from "../../../components/Modals/VirtualWallet/ChoosePeriodExport";
import NavBarAgent from "../../../components/NavBar/Agent_Partner";

const VirtualWalletAgent: React.FC = () => {
  const [data, setData] = useState<RecordData[]>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalItems, setTotalItems] = useState(0); // Estado para o número total de itens
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalResume, setShowModalResume] = useState(false);
  const [selectedLot, setSelectedLot] = useState<RecordData[]>([]);
  const [itemsPerPage] = useState(5);
  const [userExternalCode, setUserExternalCode] = useState<Number | null>(null);
  const [groupedData, setGroupedData] = useState<{ [key: string]: RecordData[] }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [cpfFilter, setCpfFilter] = useState("");
  const [bancoFilter, setBancoFilter] = useState<{ value: string; label: string } | null>(null);
  const [showModalPeriod, setShowModalPeriod] = useState<boolean>(false);

  const subtitleText = "Verifique as oportunidades geradas da sua carteira";
  const bankOptions = [
    { value: "c6", label: "C6 Bank" },
    { value: "bmg", label: "BMG"}
  ];

  // Função de busca de dados
  const fetchData = async (page: number) => {
    setLoading(true);
    setErrorMessage(null);
    setData([]); // Limpa os dados antes da nova requisição
    
    const token = localStorage.getItem("token");
    let profileData;

    try {
      const profileResponse = await fetch("https://webservices.gfttech.com.br/api/v1/usuario/perfil-usuario", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      profileData = await profileResponse.json();

      if (profileData?.Usuario?.userExternalCode === "Não possui codigo externo") {
        setErrorMessage("Você não possui código externo cadastrado. Favor contatar o suporte.");
        setLoading(false);
        return;
      } else {
        setUserExternalCode(profileData.Usuario.userExternalCode);
      }
    } catch (error) {
      console.error("Erro ao verificar perfil do usuário:", error);
      setErrorMessage("Erro ao carregar perfil do usuário.");
      setLoading(false);
      return;
    }

    const codExterno = Number(profileData?.Usuario?.userExternalCode || "0");
    let url = `https://acelereai.gfttech.com.br/api/v2/engine/opportunities?codExterno=${codExterno}&limit=${itemsPerPage}&page=${page}`;

    if (cpfFilter) {
      url += `&cpf=${cpfFilter}`;
    }
    
    if (bancoFilter?.value) {
      url += `&banco=${bancoFilter.value}`;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (result.error) {
        setErrorMessage(result.message || "Erro ao buscar dados.");
        setLoading(false);
        return;
      }

      if (result.data && result.data.length > 0) {
        setData(result.data);
        setTotalAmount(result.totalAmount || 0);
        setTotalItems(result.totalItems || 0); // Atualiza o total de itens
      } else {
        setErrorMessage("Nenhum resultado encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      setErrorMessage("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  const showLotDetails = (lotId: string) => {
    const selected = data.filter(item => item.idLoteSimulacao === lotId);
    setSelectedLot(selected);
    setShowModal(true);
  };

  const showLotOnResume = (lotId: string) => {
    const selected = data.filter(item => item.idLoteSimulacao === lotId);
    setSelectedLot(selected);
    setShowModalResume(true);
  };

  const handleSavePeriod = (startDate: string, endDate: string) => {
    exportToExcel(startDate, endDate);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]); // Quando a página mudar, busca novamente os dados

  useEffect(() => {
    setCurrentPage(1); // Resetar para a página 1 sempre que os filtros mudarem
  }, [cpfFilter, bancoFilter]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const exportToExcel = async (startDate: string, endDate: string) => {
    const token = localStorage.getItem("token"); // Obtém o token de autenticação
    const codExterno = userExternalCode; // Usa o código externo do usuário
  
    if (!codExterno) {
      console.error("Código externo do usuário não está disponível.");
      return;
    }
  
    // Monta a URL da API com os parâmetros
    const url = `https://acelereai.gfttech.com.br/api/v2/engine/opportunities/download?codExterno=${codExterno}&dataInicio=${startDate}&dataFim=${endDate}`;
  
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        setErrorMessage("Erro ao baixar o arquivo.")
      }
  
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `Acelera_Carteira_Virtual${startDate}_${endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Erro ao exportar para Excel:", error);
    }
  };

  return (
    <div className={`${styles.walletContainer} mt-5`}>
      <div className={`${styles.titlesSection}`}>
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Carteira Virtual</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-5`}>
          <PageSubTitle size="h5">{subtitleText}</PageSubTitle>
        </div>
      </div>

      <NavBarAgent />

      {errorMessage && (
        <Alert variant="secondary">
          {errorMessage}
        </Alert>
      )}

      <div className={styles.filterContainer}>
        <Row>
          <Col sm="12" md="6">
            <Form.Control
              type="text"
              placeholder="Filtrar por CPF"
              value={cpfFilter}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setCpfFilter(e.target.value)}
              className={styles.filterInput}
            />
          </Col>
          <Col sm="12" md="4">
            <Select
              options={bankOptions}
              value={bancoFilter}
              onChange={(selected) => setBancoFilter(selected)}
              placeholder="Filtrar por Banco"
              isClearable
              className={styles.filterInput}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} 
            />
          </Col>
          <Col sm="12" md="2">
            <ButtonPrimary
              content={<FontAwesomeIcon icon={faSearch} />}
              onClick={() => fetchData(1)}
            />
          </Col>
        </Row>
      </div>

      {loading ? (
        <>
        <div className="d-flex justify-content-center mt-5">
          <Spinner animation="border" />
        </div>
        </>
      ) : (
        <>
          <Row className="mt-4 mb-3">
            <Col className="d-flex justify-content-start">
              {/* <div>
                <Badge pill bg="secondary" className="p-3" style={{fontSize: 15}}>
                  Total: {formatCurrency(totalAmount)}
                </Badge>
              </div> */}
            </Col>
            <Col className="d-flex justify-content-end">
              <div>
                <ButtonPrimary
                  content={<FontAwesomeIcon icon={faDownload} />}
                  onClick={() => setShowModalPeriod(true)}
                />
              </div>
            </Col>
          </Row>
          <Table striped bordered hover responsive className={styles.table}>
            <thead>
              <tr>
                <th>Simulação</th>
                <th>Banco</th>
                <th>CPF</th>
                <th>Matrícula</th>
                <th>Operação</th>
                <th>Prazo</th>
                <th>Bruto</th>
                <th>Liquido</th>
                <th>Condições</th>
                <th>Cliente</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((record, index) => (
                  <tr key={index}>
                    <td>{formatDate(record.dataSimulacao) || ""}</td>
                    <td>{renderBankName(record.banco) || ""}</td>
                    <td>{formatCPF(record.cpf) || ""}</td>
                    <td>{record.beneficio || ""}</td>
                    <td>{record.tipoOperacao || record.tipoSaque}</td>
                    <td>{record.parcelas || ""}</td>
                    <td>{formatCurrency(record.valorLiquido) || ""}</td>
                    <td>{formatCurrency(record.valorCliente) || ""} {formatCurrency(record.valorSaqueMaximo)}</td>
                    <td>
                      <ButtonGrayUltimate
                        content={<FontAwesomeIcon icon={faList} />}
                        onClick={() => showLotDetails(record.idLoteSimulacao)}
                      />
                    </td>
                    <td>
                      <ButtonGrayUltimate
                        content={<FontAwesomeIcon icon={faArrowUpFromBracket} />}
                        onClick={() => showLotOnResume(record.idLoteSimulacao)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12} className="text-center">Nenhum dado encontrado</td>
                </tr>
              )}
            </tbody>
          </Table>

          {totalItems > itemsPerPage && (
            <Pagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}

      <LotDetailsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        selectedLot={selectedLot}
      />
      <ResumeClientModal
        show={showModalResume}
        onHide={() => setShowModalResume(false)}
        selectedLot={selectedLot}
      />

      <PeriodModal
        show={showModalPeriod}
        onHide={() => setShowModalPeriod(false)}
        onSave={handleSavePeriod}
      />
    </div>
  );
};

export default VirtualWalletAgent;