import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import GeneralPanel from './pages/General_Panel';
import MyAccount from './pages/My_Account';
import {jwtDecode} from 'jwt-decode'; 
import { Spinner } from 'react-bootstrap';
import UnitaryQuery from './pages/Query/UnitaryQuery';
import UnitaryFields from './pages/Query/UnitaryQuery/UnitaryFields';
import ConvenantSelection from './pages/Query/UnitaryQuery/ConvenantSelection';
import ProductSelection from './pages/Query/UnitaryQuery/SelectProduct';
import ResumeSimulation from './pages/Query/UnitaryQuery/ResumeSimulation';
import ResultSimulation from './pages/Query/UnitaryQuery/ResultSimulation';
import BatchQuery from './pages/Query/BatchQuery';
import ConvenantSelectionBatch from './pages/Query/BatchQuery/ConvenantSelectionBatch';
import SendFile from './pages/Query/BatchQuery/SendFile';
import ReturnBatchQuery from './pages/Query/BatchQuery/ReturnBatchQuery';
import SelectCovenantUnitary from './pages/Covenants/Unitary/SelectCovenant';
import ProductSelectionInss from './pages/Inss/Unitary/SelectProductInss';
import ClientData from './pages/Inss/Unitary/ClientData';
import SelectBanksInss from './pages/Inss/Unitary/SelectBanks';
import ProductSelectionCovenant from './pages/Covenants/Unitary/SelectProductCovenant';
import SelectBanksCovenant from './pages/Covenants/Unitary/SelectBanksCovenant';
import ClientDataCovenant from './pages/Covenants/Unitary/ClientCpf';
import ReturnSimulationCovenant from './pages/Covenants/Unitary/ReturnSimulationCovenant';
import SelectCovenantBatch from './pages/Covenants/Batch/SelectCovenant';
import ProductSelectionCovenantBatch from './pages/Covenants/Batch/SelectProductCovenant';
import SelectBanksCovenantBatch from './pages/Covenants/Batch/SelectBanksCovenant';
import SendFileCovenantBatch from './pages/Covenants/Batch/SendFile';
import ReturnBatchQueryCovenant from './pages/Covenants/Batch/ReturnBatchCovenant';
import SearchCovenant from './pages/SearchCovenant';
import ResultSimulationInss from './pages/Inss/Unitary/ResultSimulation';
import ProductSelectionInssBatch from './pages/Inss/Batch/SelectProductInssBatch';
import SelectBanksInssBatch from './pages/Inss/Batch/SelectBanksBatch';
import SendFileInssBatch from './pages/Inss/Batch/SendFileBatchInss';
import ReturnBatchQueryInss from './pages/Inss/Batch/ReturnBatchInss';
import MySimulations from './pages/MySimulations';
import Notifications from './pages/Notifications';
import VirtualWallet from './pages/VirtualWallet';
import SelectBanksInssTypingBatch from './pages/Typing/Inss/Batch/SelectBanks';
import SendFileInssBatchTyping from './pages/Typing/SendFileInssBatchTyping';
import TypingList from './pages/Typing/TypingList';
import TypingListEffected from './pages/Typing/TypingListEffected';
import SelectBanksInssTypingBatchAgent from './pages/Agent_Partner/Typing/Inss/Batch/SelectBanks';
import TypingListAgent from './pages/Agent_Partner/Typing/TypingList';
import TypingListEffectedAgent from './pages/Agent_Partner/Typing/TypingListEffected';
import SendFileInssBatchTypingAgent from './pages/Agent_Partner/Typing/SendFileInssBatchTyping';
import GeneralPanelAgent from './pages/Agent_Partner/General_Panel';
import Register from './pages/Register';
import MyAccountAgent from './pages/Agent_Partner/My_Account';
import PortabilityParameters from './pages/Portability/Parameters';
import ChangePassword from './pages/ChangePassword';
import SimulatorPortability from './pages/Portability/SimulatorPort';
import RegisterApoioCorban from './pages/ApoioCorban/Register';
import SearchCovenantAgent from './pages/Agent_Partner/SearchCovenant';
import SelectCovenantUnitaryAgent from './pages/Agent_Partner/Covenants/Unitary/SelectCovenant';
import SelectBanksCovenantAgent from './pages/Agent_Partner/Covenants/Batch/SelectBanksCovenant';
import ClientDataCovenantAgent from './pages/Agent_Partner/Covenants/Unitary/ClientCpf';
import ReturnDataCovenantAgent from './pages/Agent_Partner/Covenants/Unitary/ReturnSimulationCovenant';
import SelectBanksUnitaryCovenantAgent from './pages/Agent_Partner/Covenants/Unitary/SelectBanksCovenant';
import SelectCovenantBatchAgent from './pages/Agent_Partner/Covenants/Batch/SelectCovenant';
import ProductSelectionCovenantBatchAgent from './pages/Agent_Partner/Covenants/Batch/SelectProductCovenant';
import SendFileCovenantBatchAgent from './pages/Agent_Partner/Covenants/Batch/SendFile';
import ReturnBatchQueryCovenantAgent from './pages/Agent_Partner/Covenants/Batch/ReturnBatchCovenant';
import MySimulationsAgent from './pages/Agent_Partner/MySimulations';
import ConfirmDataClient from './pages/VirtualWallet/ConfirmDataClient';
import VirtualWalletAgent from './pages/Agent_Partner/VirtualWallet';
import ConfirmDataClientAgent from './pages/Agent_Partner/VirtualWallet/ConfirmDataClient';
import DashboardApoioCorban from './pages/ApoioCorban/Dashboard';

async function checkAuthentication() {
    const token = localStorage.getItem('token');

    if (!token) {
        return false;
    }

    try {
        const decodedToken = jwtDecode(token);
        const expiration = decodedToken.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        if (expiration <= currentTime) {
            console.log("Token expirado");
            localStorage.removeItem('token');
            localStorage.clear();
            alert("Acesso expirado! Logar novamente.");
            window.location.href = '/login';
            return false;
        }

        return true;
    } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        return false;
    }
}

function ProtectedRoute({ element: Component, routeType, ...rest }) {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const authenticate = async () => {
            const result = await checkAuthentication();
            setIsAuthenticated(result);
        };

        authenticate();
    }, []);

    if (isAuthenticated === null) {
        return <Spinner animation="border" />;
    }

    const typePartner = localStorage.getItem('typePartner');
    if ((typePartner === '1' && routeType === 'sub') || (typePartner === '2' && routeType === 'agent')) {
        return <Navigate to="/login" />;
    }

    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
}

export default function AppRoutes() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/PrimeiroAcesso" element={<ChangePassword/>} />

                {/* Sub */}
                <Route path="/PainelGeral" element={<ProtectedRoute element={GeneralPanel}  routeType="sub" />} />
                <Route path="/MinhaConta" element={<ProtectedRoute element={MyAccount}   routeType="sub" />} />
                <Route path="/ListagemSimulacoes" element={<ProtectedRoute element={MySimulations}  routeType="sub"  />} />
                <Route path="/Notificacoes" element={<ProtectedRoute element={Notifications}   routeType="sub" />} />

                <Route path="/ConsultaUnitaria" element={<ProtectedRoute element={UnitaryQuery}  routeType="sub" />} />

                {/*Sub Convenios - Unitaria */}
                <Route path="/ConsultaUnitaria/Convenios" element={<ProtectedRoute element={SelectCovenantUnitary}  routeType="sub" />} />
                <Route path="/ConsultaUnitaria/Convenios/SelecaoProduto" element={<ProtectedRoute element={ProductSelectionCovenant}  routeType="sub" />} />
                <Route path="/ConsultaUnitaria/Convenios/SelecaoBancos" element={<ProtectedRoute element={SelectBanksCovenant}  routeType="sub" />} />
                <Route path="/ConsultaUnitaria/Convenio/DadosCliente" element={<ProtectedRoute element={ClientDataCovenant} routeType="sub"  />} />
                <Route path="/ConsultaUnitaria/Convenio/Resultado" element={<ProtectedRoute element={ReturnSimulationCovenant} routeType="sub"  />} />
                
                {/*Sub Convenios - Em Lote */}
                <Route path="/ConsultaLote/Convenios" element={<ProtectedRoute element={SelectCovenantBatch}  routeType="sub" />} />
                <Route path="/ConsultaLote/Convenios/SelecaoProduto" element={<ProtectedRoute element={ProductSelectionCovenantBatch}  routeType="sub" />} />
                <Route path="/ConsultaLote/Convenios/SelecaoBancos" element={<ProtectedRoute element={SelectBanksCovenantBatch}  routeType="sub" />} />
                <Route path="/ConsultaLote/Convenios/EnvioArquivo" element={<ProtectedRoute element={SendFileCovenantBatch}  routeType="sub" />} />
                <Route path='/ConsultaLote/Convenios/Retorno' element={<ProtectedRoute element={ReturnBatchQueryCovenant}  routeType="sub" />}/>

                {/*Sub INSS - Unitaria*/}
                <Route path="/ConsultaUnitaria/Inss/Produtos" element={<ProtectedRoute element={ProductSelectionInss}  routeType="sub" />} />
                <Route path="/ConsultaUnitaria/Inss/DadosCliente" element={<ProtectedRoute element={ClientData}  routeType="sub" />} />
                <Route path="/ConsultaUnitaria/Inss/SelecaoBancos" element={<ProtectedRoute element={SelectBanksInss}  routeType="sub" />} />
                <Route path="/ConsultaUnitaria/Inss/Resultado" element={<ProtectedRoute element={ResultSimulationInss} routeType="sub" />} />

                {/*Sub INSS - Em Lote */}
                <Route path="/ConsultaLote/INSS/" element={<ProtectedRoute element={ProductSelectionInssBatch}  routeType="sub" />} />
                <Route path="/ConsultaLote/INSS/SelecaoBancos" element={<ProtectedRoute element={SelectBanksInssBatch}  routeType="sub" />} />
                <Route path="/ConsultaLote/Inss/EnvioArquivo" element={<ProtectedRoute element={SendFileInssBatch}  routeType="sub" />} />
                <Route path="/ConsultaLote/Inss/Retorno" element={<ProtectedRoute element={ReturnBatchQueryInss}  routeType="sub" />} />
                

                {/*Sub Consulta Convenios */}
                <Route path="/PesquisaConvenios" element={<ProtectedRoute element={SearchCovenant} routeType="sub" />} />

                <Route path="/ConsultaLote" element={<ProtectedRoute element={BatchQuery}  routeType="sub" />} />
                <Route path="/CamposUnitarios" element={<ProtectedRoute element={UnitaryFields}  routeType="sub" />} />
                <Route path="/SelecaoConvenios" element={<ProtectedRoute element={ConvenantSelection}  routeType="sub" />} />
                <Route path="/ConsultaLote/SelecaoConvenios" element={<ProtectedRoute element={ConvenantSelectionBatch}  routeType="sub" />} />
                <Route path="/ConsultaLote/EnvioArquivo" element={<ProtectedRoute element={SendFile}  routeType="sub" />} />
                <Route path="/ConsultaLote/Retorno" element={<ProtectedRoute element={ReturnBatchQuery}  routeType="sub" />} />
                <Route path="/SelecaoProdutos" element={<ProtectedRoute element={ProductSelection}  routeType="sub" />} />
                <Route path="/RevisaoSimulacao" element={<ProtectedRoute element={ResumeSimulation}  routeType="sub" />} />
                <Route path="/ResultadoSimulacao" element={<ProtectedRoute element={ResultSimulation}  routeType="sub" />} />

                {/* Digitação - Sub */}
                <Route path="/DigitacoesLote/INSS/SelecaoBancos" element={<ProtectedRoute element={SelectBanksInssTypingBatch}  routeType="sub" />} />
                <Route path="/DigitacoesLote/INSS/EnvioArquivo" element={<ProtectedRoute element={SendFileInssBatchTyping}  routeType="sub" />} />
                <Route path="/Digitacoes/Agendadas" element={<ProtectedRoute element={TypingList}  routeType="sub" />} />
                <Route path="/Digitacoes/Efetivadas" element={<ProtectedRoute element={TypingListEffected}  routeType="sub" />} ></Route>


                {/* Agente */}
                <Route path="/Agente/PainelGeral" element={<ProtectedRoute element={GeneralPanelAgent}  routeType="agent" />} />
                <Route path="/Agente/MinhaConta" element={<ProtectedRoute element={MyAccountAgent}  routeType="agent"/>} />
                <Route path="/Agente/Notificacoes" element={<ProtectedRoute element={Notifications}  routeType="agent"/>} />
                <Route path="/Agente/ListagemSimulacoes" element={<ProtectedRoute element={MySimulationsAgent}  routeType="agent"  />} />

                {/* Agente - Convenios - Unitaria */}
                <Route path="/Agente/ConsultaUnitaria/Convenios" element={<ProtectedRoute element={SelectCovenantUnitaryAgent}  routeType="agent" />} />
                <Route path="/Agente/ConsultaUnitaria/Convenios/SelecaoBancos" element={<ProtectedRoute element={SelectBanksUnitaryCovenantAgent}  routeType="agent" />} />
                <Route path="/Agente/ConsultaUnitaria/Convenio/DadosCliente" element={<ProtectedRoute element={ClientDataCovenantAgent} routeType="agent"  />} />
                <Route path="/Agente/ConsultaUnitaria/Convenio/Resultado" element={<ProtectedRoute element={ReturnDataCovenantAgent} routeType="agent"  />} />

                {/* Agente - Convenios - Em Lote */}
                <Route path="/Agente/ConsultaLote/Convenios" element={<ProtectedRoute element={SelectCovenantBatchAgent}  routeType="agent" />} />
                <Route path="/Agente/ConsultaLote/Convenios/SelecaoProduto" element={<ProtectedRoute element={ProductSelectionCovenantBatchAgent}  routeType="agent" />} />
                <Route path="/Agente/ConsultaLote/Convenios/SelecaoBancos" element={<ProtectedRoute element={SelectBanksCovenantAgent}  routeType="agent" />} />
                <Route path="/Agente/ConsultaLote/Convenios/EnvioArquivo" element={<ProtectedRoute element={SendFileCovenantBatchAgent}  routeType="agent" />} />
                <Route path='/Agente/ConsultaLote/Convenios/Retorno' element={<ProtectedRoute element={ReturnBatchQueryCovenantAgent}  routeType="agent" />}/>


                {/* Digitação - Agente */}
                <Route path="/Agente/DigitacoesLote/INSS/SelecaoBancos" element={<ProtectedRoute element={SelectBanksInssTypingBatchAgent} routeType="agent"/>} />
                <Route path="/Agente/DigitacoesLote/INSS/EnvioArquivo" element={<ProtectedRoute element={SendFileInssBatchTypingAgent} routeType="agent"/>} />
                <Route path="/Agente/Digitacoes/Agendadas" element={<ProtectedRoute element={TypingListAgent} routeType="agent"/>} />
                <Route path="/Agente/Digitacoes/Efetivadas" element={<ProtectedRoute element={TypingListEffectedAgent} routeType="agent"/>} ></Route>
                <Route path="/Agente/PesquisaConvenios" element={<ProtectedRoute element={SearchCovenantAgent} routeType="agent" />} />

                {/* Cadastro */}
                <Route path="/HomeCadastro" element={<ProtectedRoute element={Register} />} />

                {/* Portabilidade */}
                <Route path="/Portabilidade/Parametros" element={<ProtectedRoute element={PortabilityParameters} />} />
                <Route path="/Portabilidade/Simulador" element={<ProtectedRoute element={SimulatorPortability} />} />

                {/* Apoio Corban */}
                <Route path="/ApoioCorban/PainelGeral" element={<ProtectedRoute element={RegisterApoioCorban} />} />
                <Route path="/ApoioCorban/Dashboard" element={<ProtectedRoute element={DashboardApoioCorban} />} />

                {/* Carteira Virtual - Sub */}
                <Route path="/CarteiraVirtual" element={<ProtectedRoute element={VirtualWallet} routeType="sub"/>} />
                <Route path="/CarteiraVirtual/ConfirmacaoDadosCliente" element={<ProtectedRoute element={ConfirmDataClient} routeType="sub"/>} />

                {/* Carteira Virtual - Agente */}
                <Route path="Agente/CarteiraVirtual" element={<ProtectedRoute element={VirtualWalletAgent} routeType="agent"/>} />
                <Route path="Agente/CarteiraVirtual/ConfirmacaoDadosCliente" element={<ProtectedRoute element={ConfirmDataClientAgent} routeType="agent"/>} />
            </Routes>
        </Router>
    );
}