import { Form } from "react-bootstrap";
import styles from './PersonalData.module.scss';
import { useEffect, useState } from "react";
import ButtonPrimary from "../../../../components/Buttons/ButtonPrimary";
import ToastWarning from "../../../../components/ToastWarning";
import { BASE_URL } from "../../../../config";
import { formatCpfCnpj, limitCpfCnpj } from "../../../../Utils";
import { EmployeeResponse, PartnerResponse } from "./interface";

const PersonalData = () => {
    const [name, setName] = useState('');
    const [cpf, setCpf] = useState('');
    const [nomFantasia, setNomFantasia] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [isPartner, setIsPartner] = useState<boolean>(false);
    const token = localStorage.getItem('token');

    const buttonDesable = () => {
        return (
            !name || name.trim() === '' || 
            !cpf || cpf.trim() === '' || 
            !nomFantasia || nomFantasia.trim() === '' || 
            cpf.length < 14      
        );
    }

    const handleData = async () => {
        try {
            const res = await fetch(`${BASE_URL}/parceiro/dados-logado`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                const data: EmployeeResponse | PartnerResponse = await res.json();

                if('partnerData' in data){
                    const personalData = data.partnerData;
                    console.log(personalData);
                    
                    setIsPartner(true);
                    setName(personalData.full_name || "");
                    setCpf(personalData.cpf === '' ? formatCpfCnpj(personalData.cnpj) : formatCpfCnpj(personalData.cpf));
                    setNomFantasia(personalData.fantasy_name);
                    localStorage.setItem('IdUser', personalData.user_id.toString());
                } else if('employeeData' in data){
                    const personalData = data.employeeData;
                    console.log(personalData);
                    
                    setIsPartner(false);
                    setName(personalData?.full_name || "");
                    setCpf(personalData.cpf === '' ? formatCpfCnpj(personalData.cnpj) : formatCpfCnpj(personalData.cpf));
                    setNomFantasia("");
                    localStorage.setItem('IdUser', personalData.user_id.toString());
                }

            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleData();
    }, []);

    const keyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter' && !buttonDesable()){
            handleData();
        }
    };

    const handleSubmit = async () => {
        try{
            const res = await fetch(`${BASE_URL}/usuario/acelereai/dados-pessoais`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    full_name: name,
                    cpfCnpj: cpf,
                    fantasy_name: nomFantasia,
                }),
            });

            if (res.ok) {
                setToastMessage('Dados alterados com sucesso!');
            } else {
                setToastMessage('Erro ao alterar dados');
            }
        } catch(error){
            setToastMessage('Erro ao alterar dados');
            console.error(error);
        }

        handleData();
    }

    return (
        <section className="mt-5">
            <Form>
                <Form.Group className={styles.floatingLabel} controlId="newCpf">
                    <Form.Control
                        type="text"
                        placeholder=" "
                        value={cpf} 
                        onChange={(e) => setCpf(limitCpfCnpj(e.target.value))}
                    />
                    <Form.Label>CPF/CNPJ</Form.Label>
                </Form.Group>

                <Form.Group className={styles.floatingLabel} controlId="newName">
                    <Form.Control
                        type="text"
                        placeholder=" "
                        value={name} 
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Form.Label>Nome</Form.Label>
                </Form.Group>

                {isPartner && (
                    <Form.Group className={styles.floatingLabel} controlId="newNomFantasia">
                        <Form.Control
                            type="text"
                            placeholder=" "
                            value={nomFantasia} 
                            onKeyDown={keyPress}
                            onChange={(e) => setNomFantasia(e.target.value)}
                        />
                        <Form.Label>Nome Fantasia</Form.Label>
                    </Form.Group>
                )}

                <div className="d-flex justify-content-center">
                    <ButtonPrimary disabled={buttonDesable()} onClick={handleSubmit} content="Salvar Alterações" />
                </div>

            </Form>
            {toastMessage && <ToastWarning message={toastMessage} />}
        </section>
    );
}

export default PersonalData;
