import React from 'react';
import styles from './AnaliticoConsultas.module.scss';

const UnitariaTab: React.FC = () => {
  const data = [
    { data: '2025-01-01', cpf: '123.456.789-00', classificacao: 'Consultada' },
    { data: '2025-01-02', cpf: '987.654.321-00', classificacao: 'Convertido em Digitação' },
    { data: '2025-01-03', cpf: '456.789.123-00', classificacao: 'Consultada' },
  ];

  return (
    <table className={styles.dataTable}>
      <thead>
        <tr>
          <th>Data</th>
          <th>CPF</th>
          <th>Classificação</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.data}</td>
            <td>{item.cpf}</td>
            <td>{item.classificacao}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UnitariaTab;