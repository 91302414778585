import React from 'react';
import styles from './AnaliticoConsultas.module.scss';

const EmLoteTab: React.FC = () => {
  const data = [
    { data: '2025-01-01', quantidadeRegistros: 10, classificacao: 'Consultada' },
    { data: '2025-01-02', quantidadeRegistros: 25, classificacao: 'Convertido em Digitação' },
    { data: '2025-01-03', quantidadeRegistros: 15, classificacao: 'Consultada' },
  ];

  return (
    <table className={styles.dataTable}>
      <thead>
        <tr>
          <th>Data</th>
          <th>Quantidade de Registros</th>
          <th>Classificação</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.data}</td>
            <td>{item.quantidadeRegistros}</td>
            <td>{item.classificacao}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EmLoteTab;