export const formatCPF = (cpf: string): string => {
    const numericCpf = cpf.replace(/\D/g, '');
    return numericCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const formatCNPJ = (cnpj: string): string => {
    const numericCNPJ = cnpj.replace(/\D/g, '');
    return numericCNPJ.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
};

export const formatPhone = (phone: string): string => {
    const numericPhone = phone.replace(/\D/g, '');
    return numericPhone.replace(
    /(\d{2})(\d{5})(\d{4})/,
    '($1) $2-$3'
    );
};

export const isValidCPF = (cpf: string) => {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    let sum = 0;
    for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    let rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    return rest === parseInt(cpf.substring(10, 11));
};

export const formatCurrency = (value: any | null) => {
    if (value === null || value === undefined) return "";
    const number = parseFloat(value);
    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('pt-BR')} ${date.toLocaleTimeString('pt-BR')}`;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('pt-BR')}`;
};

export const limitCpfCnpj = (value: string) => { 
    value = value.replace(/\D/g, '');

    if (value.length > 14) {
        value = value.substring(0, 14);
    }

    return formatCpfCnpj(value);
};

export const formatCpfCnpj = (value: string) => { 
    value = value.replace(/\D/g, '');

    if (value.length <= 11) {
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else if (value.length <= 14) {
        value = value.replace(/^(\d{2})(\d)/, '$1.$2');
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
        value = value.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    }
    return value;
};

export const renderBankName = (bank: string | null) => {
    let bankName = ""
    if(bank === "c6"){
      bankName = "C6 BANK"
    }
    else if(bank === "bmg"){
      bankName = "BMG"
    }
    else if(bank === "facta"){
      bankName = "FACTA"
    }
    else if(bank === "master"){
      bankName = "MASTER"
    }
    else if(bank === "daycoval"){
      bankName = "DAYCOVAL"
    }
    else if(bank === "queromais"){
      bankName = "QUERO MAIS CRÉDITO"
    }
    else{
        bankName = "Banco Não Associado"
    }
    return bankName
}

export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text; // Retorna o texto original se for menor ou igual ao limite
  return text.slice(0, maxLength) + '...'; // Corta o texto e adiciona reticências
};

export const formatBankCode = (code: string) => {
   return code.padStart(3, '0'); // Preenche com zeros à esquerda até ter 3 caracteres
};

export const translateStatus = (status: string) => {
  if (status === "PENDING") return "PENDENTE";
  if (status === "FAILED") return "FALHA";
  if (status === "SUCCESSFULLY") return "FINALIZADO";
  return "";
};
export const translateStatusSimulation = (status: string) => {
    if (status === "pending" || status === "in_progress") return "PENDENTE";
    if (status === "FAILED") return "FALHA";
    if (status === "completed") return "CONCLUIDO";
    return "";
  };

export const getBadgeVariant = (status: string) => {
  if (status === "PENDING") return "secondary";
  if (status === "FAILED") return "danger";
  if (status === "SUCCESSFULLY") return "success";
  return "secondary";
};
export const getBadgeVariantSimulation = (status: string) => {
  if (status === "pending" || status === "in_progress") return "secondary";
  if (status === "FAILED") return "danger";
  if (status === "completed") return "success";
  return "secondary";
};

export const validarCPF = (cpf: string): boolean => {
  cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
  if (cpf.length !== 11) return false;

  // Elimina CPFs inválidos conhecidos
  if (/^(\d)\1+$/.test(cpf)) return false;

  // Valida 1º dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.charAt(9))) return false;

  // Valida 2º dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.charAt(10))) return false;

  return true;
};

export const formatarCPF = (cpf: string): string => {
  cpf = cpf.replace(/\D/g, '');
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const formatarTelefone = (telefone: string, banco: string | null): string => {
  // Remove todos os caracteres não numéricos
  telefone = telefone.replace(/\D/g, '');

  // Verifica o banco e aplica a formatação correspondente
  if (banco === "c6") {
    // Formato para C6: "85992491416" (sem formatação)
    return telefone;
  } else if (banco === "facta") {
    // Formato para Facta: "(085)99249-1416" (com zero no DDD)
    return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '(0$1)$2-$3');
  }

  // Caso o banco não seja "c6" nem "facta", retorna o telefone sem formatação
  return telefone;
};