export const maskCpf = (value: string): string => {
  // Remove tudo que não é dígito
  const cleanedValue = value.replace(/\D/g, "");

  // Aplica a máscara de CPF (000.000.000-00)
  if (cleanedValue.length <= 11) {
    return cleanedValue
      .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o primeiro ponto
      .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Adiciona o traço
  }

  // Retorna o valor sem máscara se for maior que 11 dígitos
  return cleanedValue.substring(0, 11);
};