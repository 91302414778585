import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Table, Badge, Alert, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './ResultSimulation.module.scss';
import { motion } from 'framer-motion';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import bmgLogo from '../../../../assets/images/banco bmg.png';
import c6Logo from '../../../../assets/images/banco c6.png';
import masterLogo from '../../../../assets/images/banco master.png';
import queromaisLogo from '../../../../assets/images/queromaislogo.png';
import daycovalLogo from '../../../../assets/images/daycoval-logo.png';
import factaLogo from '../../../../assets/images/logo-facta.png';
import { SimulationResult } from '../../../../interfaces/ReturnUnitaryInss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { formatCPF, formatCurrency } from '../../../../Utils';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ConfirmRedirectTypingModal from '../../../../components/Modals/ReturnSimulation';
import SimpleDigitationModal from '../../../../components/Modals/SimpleDigitation';
import axios from 'axios';
import NavBar from '../../../../components/NavBar';

const ResultSimulationCovenant: React.FC = () => {
    const [result, setResult] = useState<SimulationResult | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
    const [selectedBank, setSelectedBank] = useState<string | null>(null);
    const [selectedIdSimulation, setSelectedIdSimulation] = useState<string | null>(null);
    const [showDigitationModal, setShowDigitationModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [selectedBankForTyping, setSelectedBankForTyping] = useState<string | null>(null);
    const [convenioData, setConvenioData] = useState<{ nomeConvenio: string; urlImagem: string } | null>(null);
    const subtitleTextClient = "Retorno da sua Consulta.";

    useEffect(() => {
      const storedResult = localStorage.getItem('rs');
      if (storedResult) {
          const parsedResult = JSON.parse(storedResult);
          setResult(parsedResult);
  
          let hasOpportunities = false;
          for (const bank in parsedResult.condicoes) {
              if (parsedResult.condicoes[bank].some((cond: any) => cond.status === 'success')) {
                  hasOpportunities = true;
                  break;
              }
          }
  
          setShowAlert(!hasOpportunities);
      }
  
      // Buscar dados do convênio
      const covenantId = localStorage.getItem('covenantId');
      const token = localStorage.getItem('token'); // Recupera o token do localStorage
  
      if (covenantId && token) {
          axios.get(`https://acelereai.gfttech.com.br/api/v2/engine/covenant/${covenantId}`, {
              headers: {
                  'Authorization': `Bearer ${token}` // Adiciona o token no cabeçalho
              }
          })
          .then(response => {
              if (response.data && response.data.length > 0) {
                  setConvenioData({
                      nomeConvenio: response.data[0].nomeConvenio,
                      urlImagem: response.data[0].urlImagem
                  });
              }
          })
          .catch(error => {
              console.error("Erro ao buscar dados do convênio:", error);
          });
      }
  }, []);

    const getLogoPath = (bank: string) => {
        switch (bank) {
            case 'bmg': return bmgLogo;
            case 'master': return masterLogo;
            case 'c6': return c6Logo;
            case 'queromais': return queromaisLogo;
            case 'daycoval': return daycovalLogo;
            case 'facta': return factaLogo;
            default: return '';
        }
    };

    const handleCardClick = (bank: string) => {
        setSelectedBank(bank === selectedBank ? null : bank);
    };

    const tableVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    };

    const handleNewSimulation = () => {
        window.location.href = "/ConsultaUnitaria/Convenio/DadosCliente";
    };

    const handleConfirmSave = () => {
        setShowModalConfirm(false);
        setShowDigitationModal(true);
    };

    return (
        <>
            <section className={styles.navbarSection}>
                <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
            </section>

            <motion.div
                initial={{ x: 1000, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 1000, opacity: 0 }}
                transition={{ duration: 1.2 }}
            >
                <div className={`${styles.titlesSection} mt-5`}>
                    <div className={`${styles.titleSection}`}>
                        <PageTitle size="h2">Consulta Unitária</PageTitle>
                    </div>
                    <div className={`${styles.subTitleSection}`}>
                        <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
                    </div>
                </div>

                <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
                    {result && (
                        <>
                            <Row className='mb-3'>
                                <div className='d-flex justify-content-end'>
                                    <ButtonPrimary
                                        content={
                                            <>
                                                <FontAwesomeIcon icon={faRotateRight} className='me-2' /> <span>Simular Novamente</span>
                                            </>
                                        }
                                        onClick={handleNewSimulation}
                                    />
                                </div>
                            </Row>
                            <Card className={`${styles.resultCard} mb-5`}>
                                <Card.Header className={`${styles.headerCard} ${styles.neutralHeader}`}>
                                    <Row className="align-items-center">
                                        {convenioData && (
                                            <Col md={2} className="d-flex align-items-center justify-content-center">
                                                <img src={convenioData.urlImagem} alt={convenioData.nomeConvenio} className={styles.convenioLogo} />
                                            </Col>
                                        )}
                                        <Col md={10}>
                                            <Row>
                                            <Col md={6}>
                                                    <ListGroup as="ol">
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">{convenioData?.nomeConvenio}</div>
                                                            </div>
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <ListGroup as="ol">
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">CPF:</div>
                                                            </div>
                                                            <Badge className={styles.badgeStyle} pill>
                                                                {formatCPF(result.cpf)}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Header>
                            </Card>
                            {showAlert && (
                                <Alert variant="secondary" className="d-flex justify-content-between align-items-center">
                                    <span>Nenhuma oportunidade localizada para o benefício.</span>
                                    <Button variant="outline-dark" onClick={handleNewSimulation}>
                                        Outra Simulação
                                    </Button>
                                </Alert>
                            )}

                            <Row>
                                {Object.entries(result.condicoes).map(([bank, conditions], index) => {
                                    const successConditions = conditions.filter(cond => cond.status === 'success');
                                    const logoPath = getLogoPath(bank);
                                    if (successConditions.length > 0 && logoPath) {
                                        return (
                                            <Col xs={6} md={4} lg={2} key={index} className="mb-3">
                                                <Card
                                                    onClick={() => handleCardClick(bank)}
                                                    className={`${styles.bankCard} ${selectedBank === bank ? styles.selected : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className={styles.logoContainerSmall}>
                                                        <Card.Img variant="top" src={logoPath} alt={`${bank} Logo`} className={styles.bankLogoSmall} />
                                                    </div>
                                                    <Card.Body className="text-center">
                                                        Oportunidades: <Badge bg="secondary">{successConditions.length}</Badge>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    }
                                    return null;
                                })}
                            </Row>

                            {selectedBank && result.condicoes[selectedBank] && (
                                <motion.div
                                    className={`mt-4 ${styles.expandedTable}`}
                                    variants={tableVariants}
                                    initial="hidden"
                                    animate="visible"
                                >
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Produto</th>
                                                <th>Parcelas</th>
                                                <th>Valor da Parcela</th>
                                                <th>Valor Líquido</th>
                                                <th>Taxa Cliente</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {result.condicoes[selectedBank].filter(cond => cond.status === 'success').map((condition, i) => (
                                                <tr key={i}>
                                                    <td>{condition.produto}</td>
                                                    <td>{condition.parcelas}</td>
                                                    <td>{formatCurrency(condition.valorParcela)}</td>
                                                    <td>
                                                        {condition.produto === 'REFINANCIAMENTO' ? formatCurrency(condition.valorTroco) : formatCurrency(condition.valorLiquido)}
                                                    </td>
                                                    <td>{condition.taxaCliente?.toFixed(2)}%</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </motion.div>
                            )}
                        </>
                    )}
                </Container>
            </motion.div>
            <ConfirmRedirectTypingModal
                show={showModalConfirm}
                bankTyping={selectedBankForTyping}
                onHide={() => setShowModalConfirm(false)}
                onSave={handleConfirmSave}
            />
            <SimpleDigitationModal
                show={showDigitationModal}
                onHide={() => setShowDigitationModal(false)}
                idSimulation={selectedIdSimulation}
                bankTyping={selectedBankForTyping}
                onSave={() => {
                    setShowDigitationModal(false);
                }}
                dadosCliente={{
                    cpf: result?.cpf || '',
                    numeroBeneficio: result?.numeroBeneficio || '',
                    dataNascimento: result?.dataNascimento || ''
                }}
            />
        </>
    );
};

export default ResultSimulationCovenant;