import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import styles from './AnaliticoConsultas.module.scss';
import UnitariaTab from './UnitariaTab';
import EmLoteTab from './EmLoteTab';

interface AnaliseConsultasProps {
  onBack: () => void;
}

const AnaliseConsultas: React.FC<AnaliseConsultasProps> = ({ onBack }) => {
  const [activeTab, setActiveTab] = useState<'Unitaria' | 'EmLote'>('Unitaria');
  const data = [
    { date: '2025-01-01', consultas: 30 },
    { date: '2025-01-02', consultas: 45 },
    { date: '2025-01-03', consultas: 60 },
    { date: '2025-01-04', consultas: 50 },
    { date: '2025-01-05', consultas: 70 },
  ];

  return (
    <div className={`${styles.analiseContainer} ${styles.fadeIn}`}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack}>
          ←
        </button>
        <h5 className={styles.title}>Analítico Consultas</h5>
      </div>

      <div className={styles.chartContainer}>
        <LineChart width={800} height={250} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="consultas" stroke="#8884d8" />
        </LineChart>
      </div>

      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === 'Unitaria' ? styles.active : ''}`}
          onClick={() => setActiveTab('Unitaria')}
        >
          Unitária
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'EmLote' ? styles.active : ''}`}
          onClick={() => setActiveTab('EmLote')}
        >
          Em Lote
        </button>
      </div>

      <div className={styles.tableContainer}>
        {activeTab === 'Unitaria' ? <UnitariaTab /> : <EmLoteTab />}
      </div>
    </div>
  );
};

export default AnaliseConsultas;