import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import styles from '../Factor.module.scss'

interface BankOption {
  label: string;
  value: number;
}

interface CreateFactorModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
}

const CreateFactorModal: React.FC<CreateFactorModalProps> = ({ show, onHide, onSave }) => {
  const [dateFactor, setDate] = useState('');
  const [table, setTable] = useState<number>();
  const [value, setValue] = useState<number>();
  const [bankId, setBankId] = useState<number | null>(null);
  const [banks, setBanks] = useState<BankOption[]>([]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get('https://bi.gfttech.com.br/port/banks');
        const bankOptions = response.data.map((bank: any) => ({
          label: `${bank.bankName} (${bank.bankCode})`,
          value: bank.id, // Usando o ID do banco como value
        }));
        setBanks(bankOptions);
      } catch (error) {
        console.error('Erro ao buscar bancos:', error);
      }
    };

    fetchBanks();
  }, []);

  const handleSave = async () => {
    if (bankId === null) {
      alert('Selecione um banco antes de salvar.');
      return;
    }

    try {
      await axios.post('https://bi.gfttech.com.br/port/factor', {
        dateFactor,
        table,
        value,
        bankId,
      });
      alert('Fator criado com sucesso!');
      console.log(dateFactor);
      
      onSave();
    } catch (error) {
      alert('Erro ao criar fator.');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} className={styles.contentGeneral}>
      <Modal.Header closeButton>
        <Modal.Title>Criar Fator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Data</Form.Label>
            <Form.Control
              type="date"
              value={dateFactor}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tabela</Form.Label>
            <Form.Control
              type="number"
              value={table}
              onChange={(e) => setTable(Number(e.target.value))}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Valor</Form.Label>
            <Form.Control
              type="number"
              step="0.00001"
              value={value}
              onChange={(e) => setValue(Number(e.target.value))}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Banco</Form.Label>
            <Select
              options={banks}
              value={banks.find((bank) => bank.value === bankId)}
              onChange={(selected) => setBankId(selected ? selected.value : null)}
              placeholder="Selecione um banco"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateFactorModal;
