import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styles from './SearchUnitaryTab.module.scss';
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import styled from 'styled-components';
import leroTriste from '../../../assets/images/lerotriste.png'
import ToastWarning from "../../ToastWarning";
import SpinnerDefault from "../../Spinner";
import { isValidCPF } from "../../../Utils";
import axios from "axios";

const CovenantCard = styled(Col)`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
  }

  &:hover::before {
    content: "Ver Oportunidade";
    transition: .5s all;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #153B7F;
    color: white;
    font-family: 'PlusJakartaSans-Bold', sans-serif;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    text-align: center;
  }
`;

const CovenantName = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #153B7F;
  transition: .5s all;
`;

const CovenantLogo = styled.img`
  max-width: 100px;
  max-height: 100px;
  transition: .5s all;
  margin-bottom: 0.5rem;
`;

const NoCovenantCard = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const NoCovenantImage = styled.img`
  max-width: 110px;
  margin-bottom: 1rem;
`;

export default function SearchUnitaryTab() {
  const [toastMessage, setToastMessage] = useState('');
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState(false);
  const [covenantData, setCovenantData] = useState<any[]>([]);
  const token = localStorage.getItem('token');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setToastMessage('');
    setCovenantData([]);

    if (!cpf) {
      setToastMessage('Por favor, preencha o CPF.');
      return;
    }

    if (!isValidCPF(cpf)) {
      setToastMessage('CPF inválido.');
      return;
    }

    const cpfOnlyNumbers = cpf.replace(/[^\d]/g, '');

    try {
      setLoading(true);
      const response = await fetch(`https://webapi.gfttech.com.br/api/v2/master/customer/covenant/${cpfOnlyNumbers}`);
      if (response.ok) {
        const data = await response.json();
        setCovenantData(data);
      } else {
        const errorData = await response.json();
        setToastMessage(errorData.error || 'Erro ao buscar convênio.');
      }
    } catch (error) {
      setToastMessage('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = async (covenantId: string, covenantDescription: string) => {
    if (covenantId) {
      const cleanedCpf = cpf.replace(/[^\d]/g, '');

      localStorage.setItem('formData', JSON.stringify({
        cpf: cleanedCpf,
        covenantId: `${covenantId}`,
        covenantDescription: covenantDescription,
        productId: "1",
        productDescription: "NOVO"
      }));

      localStorage.setItem('selectedBanks', JSON.stringify(["master"],["bmg"]));
      localStorage.setItem('covenantId', `${covenantId}`);
      localStorage.setItem('covenantDescription', covenantDescription);

      const formData = {
        cpf: cleanedCpf,
        idConvenio: covenantId,
        bancos: ['2']
      };

      setLoading(true);

      try {
        // Realiza a requisição POST com timeout
        const response = await axios.post(`https://acelereai.gfttech.com.br/api/v2/engine/simulate`, formData, {
          timeout: 60000,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        localStorage.setItem('rs', JSON.stringify(response.data))

        window.location.href = `/Agente/ConsultaUnitaria/Convenio/Resultado`;
      } catch (error) {
        if (axios.isCancel(error)) {
          setToastMessage('A requisição demorou muito e foi cancelada. Tente novamente.');
        } else if (axios.isAxiosError(error) && error.response) {
          // Se o erro for um AxiosError e contiver uma resposta do servidor
          setToastMessage(error.response.data.message || 'Ocorreu um erro inesperado.');
        } else {
          // Caso o erro não seja um AxiosError
          setToastMessage('Ocorreu um erro inesperado. Tente novamente.');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Row className="d-flex justify-content-center mt-5">
        <Col sm={8} md={10}>
          <Form.Group className={styles.floatingLabel} controlId="cpf">
            <IMaskInput
              mask="000.000.000-00"
              className={`${styles.input} form-control`}
              placeholder="CPF*"
              value={cpf}
              onAccept={(value: string) => setCpf(value)}
            />
            <Form.Label>CPF*</Form.Label>
          </Form.Group>
        </Col>
        <Col sm={4} md={2}>
          <ButtonPrimary
            content={<><FontAwesomeIcon icon={faSearch} /></>}
            onClick={handleSubmit}
          />
        </Col>
      </Row>

      {loading && (
        <SpinnerDefault/>
      )}

      {toastMessage && <ToastWarning message={toastMessage} />}

      {covenantData.length > 0 && (
        <Row className="d-flex justify-content-center mt-5">
          {covenantData.map((covenant, index) => (
            <Col xs={12} sm={6} md={6} lg={4} className="mb-4" key={index}>
              {covenant.covenantId ? (
                <CovenantCard
                  className="me-3"
                  onClick={() => handleCardClick(covenant.covenantId, covenant.covenantDescription)}
                >
                  <CovenantLogo src={covenant.path || "https://via.placeholder.com/150"} />
                  <CovenantName>{`${covenant.covenantId}`} - {`${covenant.covenantDescription}`}</CovenantName>
                </CovenantCard>
              ) : (
                <NoCovenantCard className="me-1">
                  <NoCovenantImage src={leroTriste}/>
                  <CovenantName className="mt-4">{`${covenant.covenantDescription}`}</CovenantName>
                </NoCovenantCard>
              )}
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}